import React, { useState, useEffect, useContext, useRef } from 'react';
import StoreContext from '../contexts/storeContext'
import analytics from '../util/analytics'

import { fromCurrency, toCurrency, toDate, dateRoundUp, capitalize } from '../util/utils'
import ReportTotals from './reportTotals';

import { Grid, CardContent, Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import { Doughnut, Bar, Line } from 'react-chartjs-2' 
import 'chartjs-plugin-colorschemes';

import { DataGrid } from '@material-ui/data-grid';

import { useReactToPrint } from 'react-to-print';

const count = (ary, classifier) => {
	ary = ary.reduce(function(counter, item) {
		var p = (classifier || String)(item);
		if (p !== undefined && p !== ''){
			counter[p] = counter.hasOwnProperty(p) ? counter[p] + 1 : 1;
		}
		return counter;
	}, {})
	let presort = Object.entries(ary)
	presort.sort((a,b) => b[1] - a[1]);
	let topArr = presort.slice(0,5);
	let otherArr = presort.slice(5);
	let sum = 0
	otherArr.forEach(arr => sum = sum + arr[1])
	let fixedObj = {}
	topArr.forEach(arr => fixedObj[arr[0]] = arr[1])
	if (sum > 0){
		fixedObj.Other = sum
		topArr.push(["Other", sum])
	}
	return [fixedObj, topArr]
}

const countDate = (ary, classifier) => {
	ary = ary.reduce(function(counter, item) {
		var p = (classifier || String)(item);
		counter[toDate(p)] = counter.hasOwnProperty(toDate(p)) ? counter[toDate(p)] + 1 : 1;
		return counter;
	}, {})
	return ary
}

const reverseObject = (obj) => { 
	let reverseList = Object.keys(obj).reverse();
	let newObj = {}
	reverseList.forEach((key) => {
		newObj[key] = obj[key]
	})
	return newObj
}

const options = {
	legend: {
		display: false
	},
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
}

const sourceOptions = {
	plugins: {
			colorschemes: {
					scheme: 'tableau.Tableau20'
			}
	}
}
const cityOptions = {
	plugins: {
			colorschemes: {
					scheme: 'brewer.SetThree12'
			}
	}
}
const stateOptions = {
	plugins: {
			colorschemes: {
					scheme: 'brewer.Paired12'
			}
	}
}
const countryOptions = {
	plugins: {
			colorschemes: {
					scheme: 'office.Excel16'
			}
	}
}
const landingOptions = {
	legend: {
		position: 'top',
	},
	plugins: {
			colorschemes: {
					scheme: 'tableau.Tableau20'
			}
	},
}

const columns = [
	{ field: 'name', headerName: 'Name', width: 200 },
	{ 
		field: 'value', 
		headerName: 'Total Value', 
		width: 175, 
		sortable: true, 
		valueFormatter: (params: ValueFormatterParams ) => {
			return toCurrency(params.value)
		} 
	},
]

const ReportSummary = (props) => {

	const [stateContext] = useContext(StoreContext)
	const [totals, setTotals] = useState([])
	const [dateData, setDateData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [dateAccumData, setDateAccumData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [sourceData, setSourceData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [originData, setOriginData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [cityData, setCityData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [stateData, setStateData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [countryData, setCountryData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [typeData, setTypeData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [landingData, setLandingData] = useState({labels: [], datasets: [{label: '', data: []}]})
	const [topLanding, setTopLanding] = useState([])
	const [valueCount, setValueCount] = useState([])
	const [saleCount, setSaleCount] = useState(0)
	const [completedCount, setCompletedCount] = useState(0)
	let uiLoading = props.loading
	const isDev = stateContext.userData.role === "developer"
	
	// React-to-Print hooks
	const componentRef = useRef();
  const printOut = useReactToPrint({
    content: () => componentRef.current,
  });

	const handlePrint = () => {
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`print_report_summary`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
		printOut()
	}

	useEffect(() =>{

		const sumValues = (objArr) => {
			const output = objArr.reduce((accumulator, cur) => {
				let name = cur.name;
				let found = accumulator.find(elem => elem.name === name)
				if (found) {
					if (found.value){
						found.value = fromCurrency(found.value)
						found.value += fromCurrency(cur.value);
					} else {
						found.value = 0;
						found.value += fromCurrency(cur.value);
					}
				}
				else accumulator.push(cur);
				return accumulator;
			}, []);
			return output
		}
		

		const countAccum = (obj) => {
			let accum = 0;
			let newObj = {}
			let keyList = Object.keys(obj)
			keyList.forEach((key) => {
				accum = accum + obj[key];
				newObj[key] = accum
			})
			return newObj
		}

		let dateSet = reverseObject(countDate(dateRoundUp(JSON.parse(JSON.stringify(stateContext.filteredLeads))), function(lead) {return lead.date}))
		let dateAccumSet = countAccum(Object.assign({}, dateSet))
		let sourceSet = count(stateContext.filteredLeads, function(lead) {return lead.source})
		let originSet = count(stateContext.filteredLeads, function(lead) {return lead.leadSource})
		let citySet = count(stateContext.filteredLeads, function(lead) {return lead.city})
		let stateSet = count(stateContext.filteredLeads, function(lead) {return lead.state})
		let countrySet = count(stateContext.filteredLeads, function(lead) {return lead.country})
		let typeSet = count(stateContext.filteredLeads, function(lead) {return lead.type})
		let landingSet = count(stateContext.filteredLeads, function(lead) {return lead.landingPage})
		let valuePerCustomer = sumValues(JSON.parse(JSON.stringify(stateContext.filteredLeads)))
		valuePerCustomer.map((lead) => {
			return lead.value = fromCurrency(lead.value)
		})
		valuePerCustomer = valuePerCustomer.filter((lead) => {
				return (lead.value && lead.value !== 0)
		})
		setValueCount(valuePerCustomer)
		setDateData({
			labels: Object.keys(dateSet),
			datasets: [{
				data: Object.values(dateSet),
				backgroundColor: "#656565"
			}]
		})
		setDateAccumData({
			labels: Object.keys(dateAccumSet),
			datasets: [{
				data: Object.values(dateAccumSet),
				borderColor: "#656565",
			}]
		})
		setSourceData({
			labels: Object.keys(sourceSet[0]),
			datasets: [{
				label: "Calls by Name of Lead",
				data: Object.values(sourceSet[0]),
				// backgroundColor: ColorArray.slice(0, Object.values(sourceSet).length)
			}]
		})
		setOriginData({
			labels: Object.keys(originSet[0]),
			datasets: [{
				label: "Calls by Name of Lead",
				data: Object.values(originSet[0]),
				// backgroundColor: ColorArray.slice(0, Object.values(sourceSet).length)
			}]
		})
		setCityData({
			labels: Object.keys(citySet[0]),
			datasets: [{
				label: "Calls by Name of Lead",
				data: Object.values(citySet[0]),
				// backgroundColor: ColorArray.slice(0, Object.values(citySet).length)
			}]
		})
		setStateData({
			labels: Object.keys(stateSet[0]),
			datasets: [{
				label: "Calls by Name of Lead",
				data: Object.values(stateSet[0]),
				// backgroundColor: ColorArray.slice(0, Object.values(stateSet).length)
			}]
		})
		setCountryData({
			labels: Object.keys(countrySet[0]),
			datasets: [{
				label: "Calls by Name of Lead",
				data: Object.values(countrySet[0]),
				// backgroundColor: ColorArray.slice(0, Object.values(countrySet).length)
			}]
		})
		setTypeData({
			labels: Object.keys(typeSet[0]).map(lead => capitalize(lead)),
			datasets: [{
				label: "Lead by Type",
				data: Object.values(typeSet[0]),
				// backgroundColor: ColorArray.slice(0, Object.values(stateSet).length)
			}]
		})
		setLandingData({
			labels: Object.keys(landingSet[0]),
			datasets: [{
				label: "Leads",
				data: Object.values(landingSet[0]),
				// backgroundColor: ColorArray.slice(0, Object.values(citySet).length)
			}],
		})
		setTopLanding(landingSet[1])
	}, [stateContext.filteredLeads])

	useEffect(() => {
		isDev && console.log('updating totals')
		let qualifiedCount = 0, completedCounts = 0, saleCounts = 0, valueCount = 0;
		stateContext.filteredLeads.forEach(element => {
			let unformat = fromCurrency(element.value)
			if (element.qualified === 'yes') qualifiedCount++
			if (element.completed === 'yes') completedCounts++
			if (element.sale === 'yes') saleCounts++
			if ( !Number.isNaN(parseFloat(unformat)) && element.completed === 'yes'  ) valueCount += parseFloat(unformat)
		})
		let budget = stateContext.campaigns[stateContext.selectedCampaign] ? parseFloat(stateContext.campaigns[stateContext.selectedCampaign].budget) : 0 
		let value = parseFloat(valueCount)
		let revenue = value - budget, roi = (revenue / budget) * 100
		let totalList = [{
			id: 0,
			qualified: qualifiedCount,
			completed: completedCounts,
			sale: saleCounts,
			value: toCurrency(valueCount),
			revenue: toCurrency(revenue),
			roi: `${roi.toFixed(2)}%`,
			// roi: (parseFloat(valueCount) - parseFloat(stateContext.campaigns[stateContext.selectedCampaign].budget / parseFloat(stateContext.campaigns[stateContext.selectedCampaign].budget)),
		}];
		setTotals([...totalList])
		setSaleCount(saleCounts)
		setCompletedCount(completedCounts)
	},[stateContext.filteredLeads])

	//Begin UX
	if (uiLoading === true) { // display loading bar while loading
    return (
      <CardContent>
        {uiLoading && <CircularProgress size={150} />}
      </CardContent>
    );
  } else {
		return (
			<div className='campaignReportDetails' ref={componentRef}>
				<ReportTotals />
				<Button
					color="primary"
					variant="contained"
					type="submit"
					className="printReport reportButton"
					onClick={handlePrint}
				>
					Print Report
				</Button>
				<CardContent container>
					{totals[0] && 
						<div className="reportSummary">
							<div className='fullWidth'>
								<h2>Filterable Metrics</h2>
								<div>
									<Grid container spacing={2}>
										<Grid item md={6} xs={6} style={{display: 'flex', flexWrap: 'wrap'}}>
											<h3 style={{ width: '100%'}}>Filtered Totals</h3>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>Closing Ratio</h4>
												<p className="summaryCopy"><strong>{stateContext.filteredLeads.length}</strong> leads</p>
												<p className="summaryCopy"><strong>{saleCount}</strong> sales</p>
												<p className="summaryCopy">Ratio: <strong>{((saleCount / stateContext.filteredLeads.length) * 100).toFixed(2)}%</strong></p>
											</div>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>Completion Ratio</h4>
												<p className="summaryCopy"><strong>{saleCount}</strong> sales</p>
												<p className="summaryCopy"><strong>{completedCount}</strong> completed</p>
												<p className="summaryCopy">Ratio: <strong>{((completedCount / saleCount) * 100).toFixed(2)}%</strong></p>
											</div>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>{totals[0].qualified} Qualified Leads</h4>
											</div>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>{totals[0].completed} Completed Leads</h4>
											</div>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>{totals[0].sale} Sales</h4>
											</div>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>Total Value: {totals[0].value}</h4>
											</div>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>Revenue Gain: {totals[0].revenue}</h4>
											</div>
											<div style={{ width: '48%'}} className="filteredTotals">
												<h4>Campaign ROI: {totals[0].roi}</h4>
											</div>
										</Grid>
										<Grid item md={6} xs={6}>
											<div style={{ height: 325, width: '95%', marginBottom: '50px' }} className='summaryCustomerValue'>
												<h3>Customer Value</h3>
												<DataGrid rows={valueCount} pageSize={4} columns={columns} sortModel={[{field: 'value', sort: 'desc'}]}/>
											</div>
										</Grid>
									</Grid>
								</div>
								<div className='page-break'>
									<Grid container spacing={2}>
										<Grid item md={6} xs={6}>
											<h3>Leads by Date</h3>
											<Bar data={dateData} options={options} />
										</Grid>
										<Grid item md={6} xs={6}>
											<h3>Leads over Time</h3>
											<Line data={dateAccumData} options={options}  />
										</Grid>
										<Grid item md={6} xs={6}>
											<h3>Leads by Source</h3>
											<Doughnut data={sourceData} options={sourceOptions}/>
										</Grid>
										<Grid item md={6} xs={6}>
											<h3>Leads by Origin</h3>
											<Doughnut data={originData} options={countryOptions}/>
											<h4>Top Hits</h4>
										</Grid>
										<Grid item md={6} xs={6}>
											<h3>Leads by City</h3>
											<Doughnut data={cityData} options={cityOptions} />
										</Grid>
										<Grid item md={6} xs={6}>
											<h3>Leads by State</h3>
											<Doughnut data={stateData} options={stateOptions} />
										</Grid>
									</Grid>
								</div>
								<div className='page-break'>
									<Grid container spacing={2}>
										<Grid item md={6} xs={6}>
											<h3>Leads by Country</h3>
											<Doughnut data={countryData} options={countryOptions} />
										</Grid>
										<Grid item md={6} xs={6}>
											<h3>Leads by Type</h3>
											<Doughnut data={typeData} options={sourceOptions} />
										</Grid>
										<Grid item md={12} xs={12}>
											<h3>Top Landing Pages</h3>
											{topLanding && 
												topLanding.map((lead) => (
													<p> <strong>{((Number(lead[1]) / stateContext.filteredLeads.length) * 100).toFixed(2)}% || {lead[1]} leads</strong> - {lead[0]}</p>
												))
											}
										</Grid>
									</Grid>
								</div>
							</div>
						</div>
					}
				</CardContent>
			</div>
		);
	}
}

export default React.memo(ReportSummary);