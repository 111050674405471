import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Divider, FormGroup, FormControlLabel, Switch, AppBar, Tabs, Tab } from '@material-ui/core';
import Markdown from 'markdown-to-jsx';

import ChangelogContent from '../content/changelog.md';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});



// Begin component
const Changelog = (props) => {

	const [input, setInput] = useState('');

    const getInput = async () => {
        const mdPath = ChangelogContent;

        try {
            const mdFile = await fetch(mdPath);

            const mdText = await mdFile.text();
            
            setInput(mdText);
        } catch (err) {
            console.error('Problem reading markdown file', err);
        }
    };

    useEffect(() => {
        getInput();
    }, [getInput]);

	// Begin UX return
	
	const { classes, ...rest } = props;
	return (
		<main className={classes.content}>
			<div className={classes.toolbar} />
			<Card >
				<CardContent container>
					<Markdown>{input}</Markdown>
				</CardContent>
			</Card>
		</main>
	)
};

export default withStyles(styles)(Changelog);