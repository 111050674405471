// home.js

import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";

import axios from 'axios';

import Account from '../components/account';
import Report from '../components/report';
import Company from '../components/company';
import Changelog from '../components/changelog';
import SuperAdminConsole from '../components/superAdminConsole';
import { version, api } from '../util/utils'

import logo from '../assets/C1-Logo-White.png'

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles from '@material-ui/core/styles/withStyles';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import NotesIcon from '@material-ui/icons/Notes';
import BusinessIcon from '@material-ui/icons/Business';
import Avatar from '@material-ui/core/avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import analytics from '../util/analytics'
import { authMiddleWare } from '../util/auth'

import { StoreProvider } from '../contexts/storeContext';
import { withTheme } from '@material-ui/core';

const drawerWidth = 240;

const styles = (theme) => ({
	root: {
		display: 'flex'
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0
	},
	drawerPaper: {
		width: drawerWidth
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
  },
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0,
		marginTop: 20
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
  },
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1
  },
	toolbar: theme.mixins.toolbar
});

const Home = (props) => {

  const [render, setRender] = useState(0)
  
  const loadAccountPage = (event) => {
    analytics.logEvent('page_view', {page_title: 'Manage Account'})
    setRender(1)
  }

  const loadReportPage = (event) => {
    analytics.logEvent('page_view', {page_title: 'Campaign Report'})
    setRender(0)
  }

  const loadCompanyPage = (event) => {
    analytics.logEvent('page_view', {page_title: 'Manage Company'})
    setRender(2)
  }

  const loadChangelogPage = (event) => {
    setRender(3)
  }
  const loadSuperAdminPage = (event) => {
    analytics.logEvent('page_view', {page_title: 'Admin Console'})
    setRender(4)
  }

  const logoutHandler = (event) => {
		localStorage.removeItem('AuthToken');
    props.history.push('/login');  
  }

  const defaultPermissions = {
    viewCompanyPage: false,
    createUserAccounts: false,
    deleteUserAccounts: false,
    editUserAccounts: false,
    createCampaigns: false,
    editCampaignDetails: false,
    deleteCampaigns: false,
    createNewLeads: true,
    editLeads: true,
    importLeads: true,
    deleteLeads: false,
  }
  
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyId, setCompanyId] = useState('')
  const [profilePicture, setProfilePicture] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [userName, setUserName] = useState('')
  const [role, setRole] = useState('user')
  const [errorMsg, setErrorMsg] = useState('')
  const [country, setCountry] = useState('')
  const [uiLoading, setUiLoading] = useState(true)
  const [imageLoading,  setImageLoading] = useState(false)
  const [context, setContext] = useState({userData: {}, campaigns: [{}], company: ['',''], selectedCampaign: 0, leads: [], filteredLeads: []})
  const [campaigns, setCampaigns] = useState([])
  const [companies, setCompanies] = useState([])
  const [permissions, setPermissions] = useState(defaultPermissions)
  let history = useHistory()

  const isDev = context.userData.role === "developer"
  
  useEffect(() => {
		authMiddleWare(props.history);
    const authToken = localStorage.getItem('AuthToken');
    let userDataSet = {}, campaignList = [], initRows
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		axios
			.get(`${api}/user`)
			.then((response) => {
        userDataSet = {
          companyId: response.data.userCredentials.companyId,
          companyName: response.data.userCredentials.companyName,
          createdAt: response.data.userCredentials.createdAt,
          firstName: response.data.userCredentials.firstName,
          lastName: response.data.userCredentials.lastName,
          email: response.data.userCredentials.email,
          phoneNumber: response.data.userCredentials.phoneNumber,
          country: response.data.userCredentials.country,
          username: response.data.userCredentials.username,
          userId: response.data.userCredentials.userId,
          role: response.data.userCredentials.role,
          profilePicture: response.data.userCredentials.imageUrl,
          permissions: response.data.userCredentials.permissions ? response.data.userCredentials.permissions : defaultPermissions
        }
        analytics.logEvent(`login_${userDataSet.role}`, {page_title: 'Home', id: userDataSet.id, role: userDataSet.role, email: userDataSet.email, companyName: userDataSet.companyName, companyId: userDataSet.companyId})
        isDev && console.log(userDataSet)
        setFirstName(response.data.userCredentials.firstName)
        setLastName(response.data.userCredentials.lastName)
        setEmail(response.data.userCredentials.email)
        setPhoneNumber(response.data.userCredentials.phoneNumber)
        setCountry(response.data.userCredentials.country)
        setUserName(response.data.userCredentials.username)
        setRole(response.data.userCredentials.role)
        setProfilePicture(response.data.userCredentials.imageUrl)
        setCompanyId(response.data.userCredentials.companyId)
        if(response.data.userCredentials.permissions) setPermissions(response.data.userCredentials.permissions)
      })
      .then((response) => {
        const body = {companyId: userDataSet.companyId}
        console.log(`companyId is ${userDataSet.companyId}`)
        axios
          .get(`${api}/campaignList/${userDataSet.companyId}`)
          .then((res) => {
            setCampaigns([...res.data])
            campaignList = [...res.data]
            isDev && console.log(userDataSet)
            isDev && console.log(campaignList)
            setContext({ ...context, userData: userDataSet, company: [userDataSet.companyName, userDataSet.companyId], campaigns: campaignList })
            setUiLoading(false)
            // setContext({userData: userDataSet, campaigns: campaignList, selectedCampaign: context.selectedCampaign, leads: context.leads})
          })
          .catch((error) => {
            isDev && console.log('didnt make it')
            // if(error) {
            //   history.push('/login')
            // }
            console.log(error);
            setErrorMsg('Error in retrieving the data')   
          })
      })
      .then((res) => {
        if(userDataSet.role === 'admin' || userDataSet.role === 'developer'){
          setUiLoading(true)
          let companyData = {companyId: userDataSet.companyId}
          axios
          .get(`${api}/companies`, companyData)
          .then((response) => {
            isDev && console.log(response.data)
            setCompanies(response.data)

            setUiLoading(false)
          })
        }
      })
			.catch((error) => {
        isDev && console.log(error);
        history.push('/login')
				setErrorMsg('Error in retrieving the data')
			});
  }, []);

  const renderSwitch = (render) => {
    switch(render) {
      case 0:
        return <StoreProvider value={[context, setContext]}><Report campaigns={campaigns} /></StoreProvider>;
      case 1:
        return <StoreProvider value={[context, setContext]}><Account /></StoreProvider>;
      case 2:
        return <StoreProvider value={[context, setContext]}><Company /></StoreProvider>;
      case 3:
        return <StoreProvider value={[context, setContext]}><Changelog /></StoreProvider>;
      case 4:
        return <StoreProvider value={[context, setContext]}><SuperAdminConsole /></StoreProvider>;
      default:
        return <Report />;
    }
  }

  const RenderCompanyToggle = () => {
    if (role === 'admin' || role === 'owner' || role === 'developer' || permissions.viewCompanyPage === true) {
      return(
        <ListItem button key="Company" onClick={loadCompanyPage}>
          <ListItemIcon>
            {' '}
            <BusinessIcon />{' '}
          </ListItemIcon>
          <ListItemText primary="Manage Company" />
        </ListItem>
      )
    }
    else return(<div></div>);
    
  }

  const RenderSuperAdminToggle = () => {
    if (role === 'superAdmin' || role === 'developer') {
      return(
        <ListItem button key="Admin Console" onClick={loadSuperAdminPage}>
          <ListItemIcon>
            {' '}
            <BusinessIcon />{' '}
          </ListItemIcon>
          <ListItemText primary="Admin Console" />
        </ListItem>
      )
    }
    else return(<div></div>);
    
  }

  const RenderCompanySelector = () => {
    if (role === 'admin' || role === 'developer') {
      return(
        <FormControl variant="outlined" id="companySelectForm">
          <InputLabel>Select Company</InputLabel>
          <Select
            labelId="companySelectLabel"
            id="companySelect"
            value={context.company[0]}
            onChange={handleCompanySelect}
          >
          {companies.map((company) => (
            <MenuItem value={company.name}>{company.name}</MenuItem>
          ))}
          {/* <MenuItem value={context.company}>{context.company}</MenuItem> */}
        </Select>
      </FormControl>
      )
    }
    else return(<div></div>);
    
  }
  const handleCompanySelect = (event) => {
    isDev && console.log(`company set to ${event.target.value}`)
    let companyId = ''
    companies.forEach(company => {
      if (event.target.value === company.name) {companyId = company.companyId}
    })
    console.log(companies)
    // setContext({ ...context, company: event.target.value })
    axios
          .get(`${api}/campaignList/${companyId}`)
          .then((res) => {
            if (res.data.length > 0){
              setCampaigns([...res.data])
              let campaignList = [...res.data]
              isDev && console.log(campaignList)
              setContext({ ...context, company: [event.target.value, companyId], campaigns: campaignList, selectedCampaign: -1, campaignDirty: false })
            }
            else (
              setContext({ ...context, company: [event.target.value, companyId], campaigns: ['No Campaigns Yet'], selectedCampaign:  -1, campaignDirty: false })
            )
            // setContext({userData: userDataSet, campaigns: campaignList, selectedCampaign: context.selectedCampaign, leads: context.leads})
          })
          .catch((error) => {
            isDev && console.log('didnt make it')
            history.push('/login')
            console.log(error);
            setErrorMsg('Error in retrieving the data')   
          })
  }
  const { classes } = props;		
  if (uiLoading === true) {
    return (
      <div className={classes.root}>
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" noWrap>
              {context.userData.companyName} MAPCasting Validation App
            </Typography>
            <img src={logo} style={{ position: 'absolute', right: '30px', width: '120px'}} />
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper
          }}
        >
          <div className={classes.toolbar} />
          <Divider />
          <center>
            <Avatar src={profilePicture} className={classes.avatar} />
            <p>
              {' '}
              {firstName} {lastName}
            </p>
          </center>
          <Divider />
          <List>
            <RenderCompanySelector role />
            <ListItem button key="Report" onClick={loadReportPage}>
              <ListItemIcon>
                {' '}
                <NotesIcon />{' '}
              </ListItemIcon>
              <ListItemText primary="Campaigns" />
            </ListItem>

            <ListItem button key="Account" onClick={loadAccountPage}>
              <ListItemIcon>
                {' '}
                <AccountBoxIcon />{' '}
              </ListItemIcon>
              <ListItemText primary="Manage Account" />
            </ListItem>

            <RenderCompanyToggle role />
            <RenderSuperAdminToggle role />

            <ListItem button key="docs" onClick={() => window.open("https://docs.mapcasting.com/docs", "_blank")}>
              <ListItemIcon>
                {' '}
                <LibraryBooksIcon />{' '}
              </ListItemIcon>
              <ListItemText primary="Online Manual" />
            </ListItem>

            <ListItem button key="Logout" onClick={logoutHandler}>
              <ListItemIcon>
                {' '}
                <ExitToAppIcon />{' '}
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItem>
          </List>
        </Drawer>
        <div style={{marginBottom: "75px"}} className='contentSection'>{renderSwitch(render)}</div>
        <AppBar position="fixed" className={classes.appBarBottom}>
          <Toolbar>
            <p><strong>
              Copyright &copy; 2020 - {new Date().getFullYear()} Convergent1
            </strong></p>
            <p onClick={loadChangelogPage} style={{position: "absolute", right: "30px", cursor: "pointer"}}>Version {version}</p>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(Home);
