import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import login from './pages/login'
import resetPassword from './pages/resetPassword'
// import firebase from "firebase";
// import "firebase/analytics";
// import firebaseConfig from './util/firebaseConfig'

import analytics from './util/analytics'

import home from './pages/home';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import './App.css';

const theme = createMuiTheme({
	palette: {
		primary: {
			light: '#33c9dc',
			main: '#B5121B',
			dark: '#d50000',
			contrastText: '#fff'
		}
	}
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <div>
          <Switch>
            <Route exact path="/login" component={login}/>
            <Route exact path="/reset-password" component={resetPassword}/>
            {/* <Route exact path="/signup" component={signup}/> */}
            <Route exact path="/" component={home}/>
          </Switch>
        </div>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;