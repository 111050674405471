import React, { useState, useContext, useEffect } from 'react';
import StoreContext from '../contexts/storeContext'
import { useHistory } from "react-router-dom";

import { Button, CardContent, FormControl, InputLabel, Select, MenuItem, Modal } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';
import xlsx from 'json-as-xlsx'
import ReactJson from 'react-json-view'

import axios from 'axios';

import analytics from '../util/analytics'
import LeadCreate from './leadCreate';
import { toCurrency, validURL, toUnix, toDate, toStartOfDay, getExtension, capitalize, api } from '../util/utils'
import { bulkImportXlsx, bulkImportCsv } from '../util/bulkImport'
import { authMiddleWare } from '../util/auth';

const gridStyle = { minHeight: window.innerHeight*0.7 };

const editableCellStyle = () => {
	return {
		border: "1px dashed black",
		color: "black",
	}
};

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const sort = (arr, sortInfo) => {
	arr = [].concat(arr)
	if (!sortInfo) {
		return arr
	}
	return arr.sort((o1, o2) => {
		let v1 = o1[sortInfo.name]
		let v2 = o2[sortInfo.name]
		if (v1 === undefined) v1 = ''
		if (v2 === undefined) v2 = ''
		const result = sortInfo.type == 'number'
			? v1 - v2
			: v1.localeCompare(v2)

		return result * sortInfo.dir
	})
}

const defaultSortInfo = { name: 'date', dir: -1 };

let filteredFallback = [] // for when state doesn't keep up-to-date enough

const LeadsChart = (props) => {
  const [updateList, setUpdateList] = useState({})
  const [isUpdated, setIsUpdated] = useState(false)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [stateContext, setStateContext] = useContext(StoreContext)
	const [sortInfo, setSortInfo] = useState(defaultSortInfo)
	const [dataSource, setDataSource] = useState([...stateContext.leads])
	const [skip, setSkip] = useState(0)
	const [createState, setCreateState] = useState(false)
	const [enableSelection, setEnableSelection] = useState(true);
	const [selected, setSelected] = useState(null);
	const [modalStyle] = React.useState(getModalStyle);
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [openImportModal, setOpenImportModal] = useState(false);
	const [openContentModal, setOpenContentModal] = useState(false);
	const [contentMessage, setContentMessage] = useState('')
	const [importFile, setImportFile] = useState('')
	const [importMessage, setImportMessage] = useState('File must be in .csv, .xlsx, or .ods format')
	const [extension, setExtension] = useState('')

	let initRows = [...stateContext.leads];
	let uiLoading = props.loading
	let history = useHistory()

	const isDev = stateContext.userData.role === "developer"
	let editable = false
	if (stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer' || stateContext.userData.role === "owner" || stateContext.userData.permissions.editLeads === true) {
		editable = true
	}

	const deleteLeadButton = ({value, columnId, rowIndex}) => {
		isDev && console.log(selected)
		stateContext.leads.forEach((lead) => {
			if (lead.id === selected){
				let isManual = (lead.leadSource.toLowerCase() === "manual") ? true : false
				if (isDev) isManual = true
				if (!isManual) {
					alert('You may only delete leads that have been added by a user.');
				} else {
					handleOpenDeleteModal()
				}
				return null;
			} 
		})
	}
	const completeLeadDeletion = () => {
		authMiddleWare(history);
		let deleteObj = {}
		let newLeads = JSON.parse(JSON.stringify(stateContext.leads))
		if (!deleteObj[selected]) deleteObj[selected] = {}
		deleteObj[selected].deletedStatus = 'deleted'
		newLeads = newLeads.filter(function( obj ) {
			return obj.id !== selected;
		});
		setStateContext({ ...stateContext, leads: [...newLeads] })
		axios
      .put(`${api}/leads`, deleteObj)
      .then((response) => {
				isDev && console.log('updated lead sent to server')
      })
      .catch((error) => {
				isDev && console.log(error)
				if (error.response.status === 403) {
					history.push('/login');
				}
      })
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`delete_lead_completed`, {leadUpdateData: JSON.stringify(deleteObj), page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
		handleCloseDeleteModal()
	}
	const handleOpenDeleteModal = () => {
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`view_delete_lead`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
	};
	const handleOpenContentModal = (value) => {
		if (typeof value === 'object') {setContentMessage(value)}
		else {let obj = {message: value}; setContentMessage(obj)}
    setOpenContentModal(true);
  };

  const handleCloseContentModal = () => {
    setOpenContentModal(false);
	};
	const handleOpenImportModal = () => {
		setImportMessage('File must be in .csv, .xlsx,  or .ods format')
    setOpenImportModal(true);
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`view_import_leads`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
  };

  const handleCloseImportModal = () => {
		setImportFile('')
    setOpenImportModal(false);
	};

	// Begin file import code
	let fileReader 
	const toBuffer = (ab) => {
    var buf = Buffer.alloc(ab.byteLength);
    var view = new Uint8Array(ab);
    for (var i = 0; i < buf.length; ++i) {
        buf[i] = view[i];
    }
		return buf;
	}
	const loadImportFile = () => {
		setImportMessage('Reading file...')
		isDev && console.log(`load file to import: ${importFile.size}`)
		fileReader = new FileReader();
		fileReader.onloadend = handleFileRead;
		if (extension === 'xls' || extension === 'xlsx' || extension === 'ods') {
			fileReader.readAsArrayBuffer(importFile);
		} else {
			fileReader.readAsText(importFile);
		}
	}
	const handleFileRead = (e) => {
		authMiddleWare(history);
		setImportMessage('Preparing file for upload...')
		setImportFile('')
		let collection = {}
		const content = fileReader.result;
		let base64String = btoa(String.fromCharCode(...new Uint8Array(content)));
		if (extension === 'xls' || extension === 'xlsx' || extension === 'ods'){
			collection = bulkImportXlsx(base64String, stateContext.company[1], stateContext.company[0], stateContext.userData.username)
		} else if (extension === 'csv'){
			isDev && console.log('converting csv to json')
			collection = bulkImportCsv(content, stateContext.company[1], stateContext.company[0], stateContext.userData.username)
		}
		if (!collection.leads){
			setImportMessage('Error with file conversion. Review requirements and make sure that your file has been properly prepared.')
			console.log('error with file conversion')
			return null
		} else if (collection.leads.length >= 256) {
			setImportMessage('File exceeds upload limit of 255 leads. Split file into multiple sheets to reduce size.')
			alert('This import exceeds the length limit of 255 leads.');
			return null;
		} else {
			setImportMessage('Uploading leads...')
			axios
			.post(`${api}/importLeads/`, collection.leads)
			.then((response) => {
				isDev && console.log(response.data)
				let idArray = response.data
				let arrPointer = 0
				let leadsList = [...stateContext.leads]
				setImportMessage('Leads successfully imported!')
				stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`import_leads_completed`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
				collection.leads.forEach((lead) => {
					lead.id = idArray[arrPointer]
					lead.value = toCurrency(lead.value)
					lead.date = lead.startTime
					delete lead.startTime
					arrPointer++
					leadsList.push(lead)
				})
				isDev && console.log(collection.leads)
				// isDev && console.log(leadsList)
				setStateContext({ ...stateContext, leads: [...leadsList] })
				isDev && console.log('leads added to server')
				handleCloseImportModal()
			})
			.catch((error) => {
				if (error.response.status === 403) {
					history.push('/login');
				}
				isDev && console.log(error);
				setButtonLoading(false)
			});
		}
	};
	const onImportFileChange = event => {  
		// setImportFile(event.target.files[0])
		let localExtension = getExtension(event.target.files[0].name)
		setExtension(localExtension)
		console.log(localExtension)
		if (localExtension === 'xlsx' || localExtension === 'ods'){
			setImportFile(event.target.files[0])
			isDev && console.log(event.target.files[0])
		} else if (localExtension === 'csv') {
			setImportFile(event.target.files[0])
		} else {
			alert('Invalid file selected. Please select an .xlsx, .csv, or .ods file.')
			setImportFile('')
		}
		
	}; 
	// End File Import
  const onEditComplete = ({ value, columnId, rowIndex }) => {
		isDev && console.log(skip)
		isDev && console.log(`${rowIndex + skip}`)
		rowIndex = rowIndex + skip
		isDev && console.log(`updating ${columnId} to ${value} at index ${rowIndex}`)
		let data = [...dataSource];
		let filteredDataID = stateContext.filteredLeads[rowIndex].id;
		let fixedIndex = -1
		data.forEach((lead, i) => {
			if (lead.id === filteredDataID) {
				fixedIndex = i;
			}
		})
		isDev && console.log(`requested ID is: ${filteredDataID}, filteredIndex is ${rowIndex}, original index is ${fixedIndex}, origin id is ${data[fixedIndex].id}`)
		// let list = JSON.parse(JSON.stringify(updateList))
		let list = {}
    if (!data[fixedIndex]) throw 'no row data found'
		const updateValue = (loot) => {
			if (loot) { data[fixedIndex][columnId] = loot} else {data[fixedIndex][columnId] = value;}
			const indexId = data[fixedIndex].id
			if (!list[indexId]) list[indexId] = {}
			list[indexId][columnId] = value
			if (columnId === "sale" && value.toLowerCase() === "yes") {
				data[fixedIndex].completed  = "yes"
				list[indexId].completed = "yes"
				data[fixedIndex].qualified  = "yes"
				list[indexId].qualified  = "yes"
			}
			if (columnId === "qualified" && value.toLowerCase() === "no") {
				data[fixedIndex].sale  = "no"
				data[fixedIndex].value  = toCurrency("0")
				data[fixedIndex].completed  = "n/a"
				list[indexId].sale = "no"
				list[indexId].value = toCurrency("0")
				list[indexId].completed  = "n/a"
			}
			return(list)
		}

		if (columnId === "qualified" || columnId === "completed" || columnId === "sale") {
			if(!value) throw 'no value found'
			value.toLowerCase()
			// if (value !== null && value.toLowerCase() !== "yes" && value.toLowerCase() !== "no" && value.toLowerCase() !== "pending"  && value.toLowerCase() !== "n/a" && value !== ''){
			// 	console.log('You have entered an invalid value. You may only enter "yes", "pending", "n/a", or "no."');
			// 	alert('You have entered an invalid value. You may only enter "yes", "pending", "n/a", or "no."');
			// } else updateValue()
			updateValue()
		} else if (columnId === "value") {
			if(!value) throw 'no value found'
			value = Number(value.replace(/[^0-9.-]+/g,""))
			let formatted = toCurrency(value)
			updateValue(formatted.toString())
		} else updateValue()
		setStateContext({ ...stateContext, leads: [...data] })
		setUpdateList(JSON.parse(JSON.stringify(list)))
		isDev && console.log(list)
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`update_lead`, {leadUpdateData: JSON.stringify(list), page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
		pushLeadsUpdates(list)
  }

  const pushLeadsUpdates = (leadUpdates) => {
		authMiddleWare(history);
		isDev && console.log('sending lead updates to server')
		let keyArr = Object.keys(leadUpdates)
		keyArr.forEach((lead) => {
			if (leadUpdates[lead].value === "$0.00") leadUpdates[lead].value = 0
		})
		// leadUpdates.map((lead) => {
		// 	if (lead.value === "$0.00") lead.value = 0
		// })
    axios
      .put(`${api}/leads`, leadUpdates)
      .then((response) => {
        setIsUpdated(false)
        initRows = [...stateContext.leads]
      })
      .then((response) => {
				isDev && console.log('updated leads sent to server')
				setUpdateList({})
      })
      .catch((error) => {
				if (error.response.status === 403) {
					history.push('/login');
				}
        isDev && console.log(error)
      })
	};

	const sortHandle = (value) => {
		isDev && console.log('sorting')
		const newDataSource = sort([...filteredFallback], value)
		const newSortInfo = value ? { type: value.type, name: value.name, dir: value.dir } : value
		setStateContext({...stateContext, filteredLeads: newDataSource})
		filteredFallback = newDataSource
    setSortInfo(newSortInfo)
	}

  useEffect(() => { // if leads list changes, reset data source and isUpdated state
		isDev && console.log('checking if change has been made')
		setDataSource([...stateContext.leads])
		filteredFallback = [...stateContext.leads]
		if (stateContext.leads !== initRows) setIsUpdated(true)
	},[stateContext.leads])

	const filterResults = () => {
		if (stateContext.campaigns[stateContext.selectedCampaign] && stateContext.campaigns[stateContext.selectedCampaign].filters ) {
			let fArr = stateContext.campaigns[stateContext.selectedCampaign].filters
			isDev && console.log(fArr)
			let accumFilter1 = JSON.parse(JSON.stringify(stateContext.leads)), accumFilter2 = []

			const fieldCheck = (field, value, lead) => {
				if (lead[field] === value) {
					return true
				} else return false
			}
			const fieldCheckIncludes = (field, value, lead) => {
				if (lead[field].includes(value)) {
					return true
				} else return false
			}

			const accumulator = (filter, field) => {
				accumFilter2 = []
				fArr[filter].forEach(value => {
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck(field, value, lead)))
				})
				accumFilter1 = accumFilter2
			}
			const accumulatorIncludes = (filter, field) => {
				accumFilter2 = []
				fArr[filter].forEach(value => {
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheckIncludes(field, value, lead)))
				})
				accumFilter1 = accumFilter2
			}

			const accumulatorNoDupes = (direction, field) => {
				accumFilter2 = []
				if (direction === 'descending'){
					for (let i=0; i<accumFilter1.length; i++){
						let count = 0
						if(accumFilter1[i][field] !== '' && accumFilter1[i][field] !== undefined && accumFilter1[i][field].toLowerCase() !== 'unavailable'){
							for (let j=0; j<accumFilter2.length; j++){
								if(accumFilter2[j][field] === accumFilter1[i][field]){
									count++
									break;
								}
							}
						}
						if (count === 0) accumFilter2.push(accumFilter1[i])
					}
				}
				if (direction === 'ascending'){
					for (let i=(accumFilter1.length-1); i>=0; i--){
						let count = 0
						for (let j=0; j<accumFilter2.length; j++){
							if(accumFilter2[j][field] === accumFilter1[i][field]){
								count++
								break;
							}
						}
						if (count === 0) accumFilter2.push(accumFilter1[i])
					}
				}
				accumFilter1 = accumFilter2
			}
			if (fArr.sourceFilters && fArr.sourceFilters.length > 0) accumulator("sourceFilters", "source")
			if (fArr.cities && fArr.cities.length > 0) accumulator("cities", "city")
			if (fArr.origins && fArr.origins.length > 0) accumulator("origins", "leadSource")
			if (fArr.states && fArr.states.length > 0) accumulator("states", "state")
			if (fArr.countries && fArr.countries.length > 0) accumulator("countries", "country")
			if (fArr.types && fArr.types.length > 0) accumulatorIncludes("types", "type")
			if (fArr.keywords && fArr.keywords.length > 0) accumulatorIncludes("keywords", "keywords")
			if (fArr.landingPages && fArr.landingPages.length > 0) accumulator("landingPages", "landingPage")
			if (fArr.startDate && fArr.startDate !== '') {
				accumFilter1 = accumFilter1.filter(lead => {if (lead.date >= toUnix(fArr.startDate)){return true} else {return false}})
			}
			if (fArr.endDate && fArr.endDate !== '') {
				let correctedDate = Number(toUnix(fArr.endDate)) + 86400
				accumFilter1 = accumFilter1.filter(lead => {if (lead.date <= correctedDate){return true} else {return false}})
			}
			if (fArr.qualifiedYes === true || fArr.qualifiedMaybe === true || fArr.qualifiedNo === true){
				accumFilter2 = []
				if (fArr.qualifiedYes === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("qualified", "yes", lead)))
				}
				if (fArr.qualifiedNo === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("qualified", "no", lead)))
				}
				if (fArr.qualifiedPending === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("qualified", "pending", lead)))
				}
				accumFilter1 = accumFilter2
			}
			if (fArr.completedYes === true || fArr.completedNA === true || fArr.completedNo === true){
				accumFilter2 = []
				if (fArr.completedYes === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("completed", "yes", lead)))
				}
				if (fArr.completedNo === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("completed", "no", lead)))
				}
				if (fArr.completedNA === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("completed", "n/a", lead)))
				}
				accumFilter1 = accumFilter2
			}
			if (fArr.saleYes === true || fArr.salePending === true || fArr.saleNo === true){
				accumFilter2 = []
				if (fArr.saleYes === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("sale", "yes", lead)))
				}
				if (fArr.saleNo === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("sale", "no", lead)))
				}
				if (fArr.salePending === true){
					accumFilter2 = accumFilter2.concat(accumFilter1.filter(lead => fieldCheck("sale", "pending", lead)))
				}
				accumFilter1 = accumFilter2
			}
			if (fArr.sourceFilters && fArr.filterDupes === '2') accumulatorNoDupes('ascending', "name")
			if (fArr.sourceFilters && fArr.filterDupes === '1') accumulatorNoDupes('descending', "name")
			if (fArr.sourceFilters && fArr.filterNumberDupes === '2') accumulatorNoDupes('ascending', "phone")
			if (fArr.sourceFilters && fArr.filterNumberDupes === '1') accumulatorNoDupes('descending', "phone")
			if (fArr.sourceFilters && fArr.filterEmailDupes === '2') accumulatorNoDupes('ascending', "email")
			if (fArr.sourceFilters && fArr.filterEmailDupes === '1') accumulatorNoDupes('descending', "email")
			setStateContext({...stateContext, filteredLeads: accumFilter1})
			filteredFallback = accumFilter1
			sortHandle(sortInfo)
			isDev && console.log(accumFilter1)
		}
	}

	const handleCreateToggle = (toggleState) => {
		isDev && console.log(`The create state has been set to ${toggleState}`)
		if (toggleState){
			stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`view_create_lead`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
		}
		setCreateState(toggleState)
	}
	const onSelectionChange = (selected) => {
		isDev && console.log(`selected id = ${selected.selected}`)
		setSelected(selected.selected)
	}
	const onHandleSave = () => {
		let xlColumns = [
			{ label: 'Date', value: row => (toDate(row.date)) },
			{ label: 'Type', value: 'type' },
			{ label: 'Name', value: 'name' },
			{ label: 'Source', value: 'source' },
			{ label: 'Landing Page', value: 'landingPage' },
			{ label: 'Origin', value: 'leadSource' },
			{ label: 'Phone Number', value: 'phone' },
			{ label: 'Email', value: 'email' },
			{ label: 'City', value: 'city' },
			{ label: 'State', value: 'state' },
			{ label: 'Country', value: 'country' },
			{ label: 'Keywords', value: 'keywords' },
			{ label: 'Content', value: 'content' },
			{ label: 'Notes', value: 'note' },
			{ label: 'Qualified', value: 'qualified' },
			{ label: 'Sale', value: 'sale' },
			{ label: 'Value', value: row => (toCurrency(row.value)) },
			{ label: 'Completed', value: 'completed' },
		]
		let today = new Date();
		let date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
		let time = today.getHours() + "-" + today.getMinutes() + "-" + today.getSeconds();
		let dateTime = date+' '+time;

		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`export_leads_list`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})

		let xlSettings = {
			sheetName: 'Leads', // The name of the sheet
			fileName: `${stateContext.company[0]} MAPCasting Leads ${dateTime}`, // The name of the spreadsheet
			extraLength: 3, // A bigger number means that columns should be wider
			writeOptions: {} // Style options from https://github.com/SheetJS/sheetjs#writing-options
		}
		let download = true
		xlsx(xlColumns, stateContext.filteredLeads, xlSettings, download) 
	}

	useEffect(() => { // filter results based on the reportFilters.js filter bar
		if (stateContext.campaigns[stateContext.selectedCampaign] && stateContext.campaigns[stateContext.selectedCampaign].filters !== "none") { 
			isDev && console.log(`updating filters with ${JSON.stringify(stateContext.campaigns[stateContext.selectedCampaign].filters)}`)
			filterResults() 
		} else {
			filteredFallback = [...stateContext.leads]
			setStateContext({...stateContext, filteredLeads: [...stateContext.leads]})
			sortHandle(sortInfo)
		}
	}, [(stateContext.campaigns[stateContext.selectedCampaign] && stateContext.campaigns[stateContext.selectedCampaign].filters), stateContext.leads])

	const columns = [
		// { name: 'id', header: 'ID', defaultFlex: 1, editable: false},
		{ 
			name: 'date', 
			header: 'Date', 
			defaultWidth: 100, 
			editable: false, 
			sortable: false,
			showColumnMenuLockOptions: false,
			render: ({ value }) => {
				return toDate(toStartOfDay(value))
			}
		},
		{ 
			name: 'type', 
			header: 'Type', 
			defaultWidth: 60, 
			editable: false, 
			sortable: false,
			showColumnMenuLockOptions: false,
			render: ({ value }) => {
				return capitalize(value)
			}
		 },
		{ name: 'name', header: 'Name', defaultWidth: 100, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'source', header: 'Source', defaultWidth: 100, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'landingPage', header: 'Landing Page', defaultWidth: 110, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'leadSource', header: 'Origin', defaultWidth: 75, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'phone', header: 'Phone', defaultWidth: 110, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'email', header: 'Email', defaultWidth: 110, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'city', header: 'City', defaultWidth: 100, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'state', header: 'State', defaultWidth: 60, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ name: 'country', header: 'Country', defaultWidth: 80, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ 
			name: 'keywords', 
			header: 'Keywords', 
			defaultWidth: 100, 
			editable: false, 
			sortable: false, 
			showColumnMenuLockOptions: false,
			render: ({ value }) => {
				return value ? value.replace(" +", ", ").replace("+", "") : ''
			} },
		// { name: 'keywords', header: 'Keywords', defaultFlex: 1, editable: false, sortable: false, showColumnMenuLockOptions: false },
		{ 
			name: 'content', 
			header: 'Content', 
			defaultWidth: 80, 
			editable: false, 
			sortable: false,
			showColumnMenuLockOptions: false,
			render:  ({ value }) => {
				if (!value) {
					return '' 
				} else if (validURL(value)) { 
					return <a class='contentReadLink' href={value} target="_blank" rel='nofollow'>Listen</a>
				} else {
					return <a class='contentReadLink' onClick={() => handleOpenContentModal(value)}>Read</a>
				}
			}
		},
		{ 
			name: 'note', 
			header: 'Notes', 
			defaultWidth: 100, 
			editable: editable, 
			sortable: false, 
			headerProps: { className: 'grid-editable'}, 
			style: editableCellStyle, 
			cellProps: {style: {borderLeft: "1px solid black"}}, 
			showColumnMenuLockOptions: false },
		{ 
			name: 'qualified', 
			header: 'Qualified', 
			editable: editable,
			defaultWidth: 80, 
			sortable: false,
			headerProps: { className: 'grid-editable'}, 
			style: editableCellStyle, 
			cellProps: {style: {borderLeft: "1px solid black"}}, 
			showColumnMenuLockOptions: false,
			render:  ({ value }) => value,
			renderEditor: (editorProps) => {
				return (
					<FormControl variant="outlined" className={`leadSelectForm`}>
						<Select
							className={`leadSelectForm`}
							value={editorProps.value}
							onClick={(event) => {
								if (event.target.value !== 0){
									editorProps.onChange(editorProps.value = event.target.value)
								}
								setTimeout(function(){ editorProps.onComplete() }, 10);
							}}
							// onBlur={editorProps.onComplete}
						>
							<MenuItem value={"yes"}>yes</MenuItem>
							<MenuItem value={"no"}>no</MenuItem>
							<MenuItem value={"pending"}>pending</MenuItem>
						</Select>
					</FormControl>
				)
			}
		},
		{ 
			name: 'sale', 
			header: 'Sale', 
			defaultWidth: 80, 
			sortable: false,
			editable: editable,
			headerProps: { className: 'grid-editable'}, 
			style: editableCellStyle, 
			showColumnMenuLockOptions: false,
			render:  ({ value }) => value,
			renderEditor: (editorProps) => {
				return (
					<FormControl variant="outlined" className="leadSelectForm">
						<Select
							className="leadSelectBox"
							value={editorProps.value}
							onClick={(event) => {
								if (event.target.value !== 0){
									editorProps.onChange(editorProps.value = event.target.value)
								}
								setTimeout(function(){ editorProps.onComplete() }, 10);
							}}
							// onBlur={editorProps.onComplete}
						>
							<MenuItem value={"yes"}>yes</MenuItem>
							<MenuItem value={"no"}>no</MenuItem>
							<MenuItem value={"pending"}>pending</MenuItem>
						</Select>
					</FormControl>
				)
			}
		},
		{ 
			name: 'value', 
			header: 'Value', 
			defaultWidth: 100, 
			sortable: false,
			editable: editable,
			headerProps: { className: 'grid-editable'}, 
			style: editableCellStyle, 
			cellProps: {style: {border: "1px solid black"}}, 
			showColumnMenuLockOptions: false,
		},
		{ 
			name: 'completed', 
			header: 'Completed', 
			defaultWidth: 100, 
			sortable: false,
			editable: editable,
			headerProps: { className: 'grid-editable'}, 
			style: editableCellStyle, 
			showColumnMenuLockOptions: false,
			render:  ({ value }) => value,
			renderEditor: (editorProps) => {
				return (
					<FormControl variant="outlined" className="leadSelectForm">
						<Select
							className="leadSelectBox"
							value={editorProps.value}
							onClick={(event) => {
								if (event.target.value !== 0){
									editorProps.onChange(editorProps.value = event.target.value)
								}
								setTimeout(function(){ editorProps.onComplete() }, 10);
							}}
							// onBlur={editorProps.onComplete}
						>
							<MenuItem value={"yes"}>yes</MenuItem>
							<MenuItem value={"no"}>no</MenuItem>
							<MenuItem value={"n/a"}>n/a</MenuItem>
						</Select>
					</FormControl>
				)
			}
		}
	];

  if (uiLoading === true) { // display loading bar while loading
    return (
      <CardContent>
        {uiLoading && <CircularProgress size={150} />}
      </CardContent>
    );
  } else {
		return (
			<CardContent>
				<Modal
					open={openDeleteModal}
					onClose={handleCloseDeleteModal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className="confirmationModal">
						<h3>Confirm Deletion</h3>
						<p>Are you sure you want to delete this lead?</p>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={completeLeadDeletion}
						>
							Confirm Delete Lead
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={handleCloseDeleteModal}
						>
							Cancel Delete Lead
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					</div>
				</Modal>
				<Modal
					open={openImportModal}
					onClose={handleCloseImportModal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className="confirmationModal">
						<h3>Bulk Import Leads</h3>
						<p>{importMessage}</p>
						<input type="file" onChange={onImportFileChange} accept=".xlsx, .ods, .csv" /> 
						<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={loadImportFile}
							disabled={
								( importFile === '' )
							}
						>
							Upload Leads
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={handleCloseImportModal}
						>
							Cancel Import
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					</div>
				</Modal>
				<Modal
					open={openContentModal}
					onClose={handleCloseContentModal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className="confirmationModal contentModal">
						<h3>Lead Content</h3>
						<ReactJson name={false} enableClipboard={false} displayDataTypes={false} quotesOnKeys='false' src={contentMessage} />
						<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={handleCloseContentModal}
						>
							Close Window
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					</div>
				</Modal>
				<div id='leadsButtons'>
					{/* <Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={pushLeadsUpdates}
						disabled={
							( isUpdated === false )
						}
					>
						Save Lead Updates
						{buttonLoading && <CircularProgress size={30} />}
					</Button> */}
					{(stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer' || stateContext.userData.permissions.createNewLeads === true) &&
						<Button
								color="primary"
								variant="contained"
								type="submit"
								className={"loadReport reportButton"}
								onClick={handleCreateToggle}
							>
								Create New Lead
								{buttonLoading && <CircularProgress size={30} />}
						</Button>
					}
					{(stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer' || stateContext.userData.role === "owner" || stateContext.userData.permissions.importLeads === true) &&
						<Button
								color="primary"
								variant="contained"
								type="submit"
								className={"loadReport reportButton"}
								onClick={handleOpenImportModal}
							>
								Import Leads
								{buttonLoading && <CircularProgress size={30} />}
						</Button>
					}
					{(stateContext.userData.role === "admin" || stateContext.userData.role === "developer" || stateContext.userData.role === "owner" || stateContext.userData.permissions.deleteLeads === true ) &&
						<Button
								color="primary"
								variant="contained"
								type="submit"
								className={"loadReport reportButton"}
								onClick={deleteLeadButton}
							>
								Delete Lead
								{buttonLoading && <CircularProgress size={30} />}
						</Button>
					}
					<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={onHandleSave}
						>
							Export Leads
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					
					{isDev &&
						<div>
							<Button color="primary" variant="contained" className="reportButton" onClick={() => { isDev && console.log(updateList) }} >Log Changes</Button>
							<Button color="primary" variant="contained" className="reportButton" onClick={() => { isDev && console.log(stateContext.filteredLeads) }} >Log Filtered Data</Button>
							<Button color="primary" variant="contained" className="reportButton" onClick={() => { isDev && console.log(filteredFallback) }} >Log Filtered Fallback</Button>
						</div>
					}
				</div>
				<div className='leadCreationSection'>
					<LeadCreate onToggleView={handleCreateToggle} visible={createState} />
				</div>
				<div id="leadsChart" className="leadsChart">
					<ReactDataGrid
						idProperty="id"
						onEditComplete={onEditComplete}
						onSortInfoChange={sortHandle}
        		onSkipChange={skipNum => {isDev && console.log(skipNum); setSkip(skipNum)}}
						editable={true}
						columns={columns}
						dataSource={stateContext.filteredLeads}
						style={gridStyle}
						enableSelection={enableSelection}
						onSelectionChange={onSelectionChange}
						pagination="local"
						defaultLimit="20"
						alwaysShowTrack="true"
						autoHide="false"
						nativeScroll="true"
						showColumnMenuLockOptions="false"
						native
					/>
				</div>
			</CardContent>
		);
	}
}

export default React.memo(LeadsChart);