import React, { useState, useEffect, useContext } from 'react';
import StoreContext from '../contexts/storeContext'

import clsx from 'clsx';

import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Divider, FormGroup, FormControlLabel, Switch, AppBar, Tabs, Tab } from '@material-ui/core';

import CampaignSelect from './campaignSelect';
import CampaignDetails from './campaignDetails';
import CampaignCreate from './campaignCreate';
import LeadsChart from './leadsChart';
import ReportFilters from './reportFilters';
import ReportSummary from './reportSummary';

import analytics from '../util/analytics'

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Begin component
const Report = (props) => {
	// establish state
	const [uiLoading, setUiLoading] = useState(false)
	const [viewState, setViewState] = useState(false)
	const [createState, setCreateState] = useState(false)
	const [filterToggle, setFilterToggle] = useState(true)
	const [tabValue, setTabValue] = useState(0)

	// bring in context
	const [stateContext, setStateContext] = useContext(StoreContext)

	const isDev = stateContext.userData.role === "developer"

	const handleCampaignSelect = (selected) => {
		isDev && console.log(`${selected} has been selected from the dropdown menu`)
	} 
	const handleViewToggle = (toggleState) => {
		isDev && console.log(`The view state has been set to ${toggleState}`)
		setViewState(toggleState)
	}
	const handleCreateToggle = (toggleState) => {
		isDev && console.log(`The create state has been set to ${toggleState}`)
		if(toggleState){
			analytics.logEvent('view_campaign_create', {username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0]})
		}
		setCreateState(toggleState)
	}
	const handleLoadToggle = (loadState) => {
		setUiLoading(loadState)
	}

	const toggleFilterView = (event) => {setFilterToggle(!filterToggle)}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
		let log = newValue === 0 ? 'view_report_summary' : 'view_leads_list'
		if(stateContext.campaigns[stateContext.selectedCampaign]){
			analytics.logEvent(`${log}`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
		}
		isDev && console.log(newValue)
  };

	// Begin UX return
	
	const { classes, ...rest } = props;
	return (
		<main className={classes.content}>
			<div className={classes.toolbar} />
			<Card >
				<CardContent container>
					<CampaignSelect onCampaignSelect={handleCampaignSelect} onCreateView={handleCreateToggle} detailsVisible={viewState} onToggleView={handleViewToggle} onLoadSet={handleLoadToggle}/>
					<CampaignDetails onLoadSet={handleViewToggle} visible={viewState}/>
					<CampaignCreate onToggleView={handleCreateToggle} visible={createState} />
					<Divider />
					<AppBar position="static">
						<Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
							<Tab label="Report Summary" {...a11yProps(0)} />
							<Tab label="Leads List" {...a11yProps(1)} />
						</Tabs>
					</AppBar>
				</CardContent>
				<CardContent className='reportContent'>
					<div className={filterToggle ? 'reportData shrink' : 'reportData full'}>
						<FormControlLabel
						className="filterToggle"
						label="Display Filters"
							control={
								<Switch
									checked={filterToggle}
									onChange={toggleFilterView}
									name="checkedB"
									color="primary"
								/>
							}
						/>
						<div className={tabValue === 0 ? "summaryContainer visible" : "summaryContainer hidden" }><ReportSummary loading={uiLoading} /></div>
						<div className={tabValue === 1 ? "leadsContainer visible" : "leadsContainer hidden" }><LeadsChart loading={uiLoading} /></div>
					</div>
					<div className='sideBar' className={filterToggle ? 'sideBar visible' : 'sideBar hidden'}>
						<ReportFilters />
					</div>
				</CardContent>
			</Card>
		</main>
	)
};

export default withStyles(styles)(Report);