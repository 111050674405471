import dayjs from 'dayjs';
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

export const toCurrency = value => {
  if (value === undefined) return
  return Number.isNaN(parseFloat(value)) ? value : new Intl.NumberFormat('USD', { style: 'currency', currency: 'USD' }).format(value)
}

export const fromCurrency = value => {
  if (!value) return
  if (value === '') value = 0
  return isNaN(value) ? Number(value.replace(/[^0-9.-]+/g,"")) : value
}

export const toUnix = value => {return isNaN(value) ? dayjs(value).format('X') : value }
	
export const toDate = value => {return isNaN(value) ? value : dayjs.unix(value).format('MM/DD/YYYY')}

export const toStartOfDay = value => {
  let dateMod = value % 86400, midnight = (Number(value) - Number(dateMod) + 86400)
  return isNaN(value) ? value : midnight}

export const dateRoundUp = (list) => {
  list.map((lead) => {
    lead.date = toStartOfDay(lead.date)
  })
  return(list)
}
export const getExtension = (filename) => {
  var parts = filename.split('.');
  return parts[parts.length - 1];
}

export const capitalize = (string) => {
  if (string){
    string = string.toLowerCase()
    return string.replace(/(^\w|\s\w)/g, m => m.toUpperCase())
  }
}

export const downloadToFile = (content, filename, contentType) => {
  const a = document.createElement('a');
  const file = new Blob([content], {type: contentType});
  
  a.href= URL.createObjectURL(file);
  a.download = filename;
  a.click();

	URL.revokeObjectURL(a.href);
};

export const validURL = (str) => {
  let pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

export const toDatePicker = value => {return isNaN(value) ? value : dayjs.unix(value).format('YYYY-MM-DD')}

export const ColorArray = ["#069668", "#76e8ca", "#355952", "#aedbf0", "#453e7d", "#feafda", "#8c3ba0", "#5d99aa", "#a7d64e", "#6e3901", "#ea8244", "#900e08", "#fe5360", "#ddc485", "#2af385", "#7f7bc9", "#eb6be6", "#8601ee", "#4853fc", "#ebc30e", "#a07a7c", "#a27f27", "#96e97c", "#1b511d", "#c6dbae", "#459521", "#2eceb7", "#588c94", "#77d1fd", "#26496d", "#707ae4", "#d179f8", "#422fae", "#d6bcf5", "#95265c", "#ec78a8", "#fd048f", "#967b95", "#fa2beb", "#5310f0", "#17f46f", "#7d4400", "#efbba2", "#e6262f", "#fd8f20", "#f1d438", "#069668", "#76e8ca", "#355952", "#aedbf0", "#453e7d", "#feafda", "#8c3ba0", "#5d99aa", "#a7d64e", "#6e3901", "#ea8244", "#900e08", "#fe5360", "#ddc485", "#2af385", "#7f7bc9", "#eb6be6", "#8601ee", "#4853fc", "#ebc30e", "#a07a7c", "#a27f27"]

export const version = "Beta 1.05"

export const api = 'https://us-central1-idyllic-physics-297416.cloudfunctions.net/api' // production

// export const api = 'http://localhost:5001/idyllic-physics-297416/us-central1/api' // development