import React, { useState, useEffect, useContext } from 'react';
import StoreContext from '../contexts/storeContext'

import { fromCurrency, toCurrency } from '../util/utils'

import { Grid, CardContent } from '@material-ui/core';

const ReportTotals = () => {

	const [stateContext] = useContext(StoreContext)
	const [totals, setTotals] = useState([])
	
	const isDev = stateContext.userData.role === "developer"

	useEffect(() => {
		isDev && console.log('updating totals')
		let qualifiedCount = 0, completedCount = 0, saleCount = 0, valueCount = 0;
		stateContext.leads.forEach(element => {
			let unformat = fromCurrency(element.value)
			if (element.qualified === 'yes') qualifiedCount++
			if (element.completed === 'yes') completedCount++
			if (element.sale === 'yes') saleCount++
			if ( !Number.isNaN(parseFloat(unformat)) && element.completed === 'yes' ) valueCount += parseFloat(unformat)
		})
		let budget = stateContext.campaigns[stateContext.selectedCampaign] ? parseFloat(stateContext.campaigns[stateContext.selectedCampaign].budget) : 0 
		let value = parseFloat(valueCount)
		let revenue = value - budget, roi = (revenue / budget) * 100
		let totalList = [{
			id: 0,
			qualified: qualifiedCount,
			completed: completedCount,
			sale: saleCount,
			value: toCurrency(valueCount),
			revenue: toCurrency(revenue),
			roi: `${roi.toFixed(2)}%`,
			// roi: (parseFloat(valueCount) - parseFloat(stateContext.campaigns[stateContext.selectedCampaign].budget / parseFloat(stateContext.campaigns[stateContext.selectedCampaign].budget)),
		}];
		setTotals([...totalList])
		isDev && console.log('totals updated')
		isDev && console.log(totals)
	},[stateContext])

  return (
		<CardContent container>
			{totals[0] && 
				<div className="reportTotals">
					<div className='fullWidth'>
						<h2>{stateContext.campaigns[stateContext.selectedCampaign] && stateContext.campaigns[stateContext.selectedCampaign].name} Campaign Totals</h2>
						<div style={{paddingRight: "3%"}}>
							<Grid container spacing={2}>
								<Grid item md={4} xs={6}>
									<h3>{totals[0].qualified} Qualified Leads</h3>
								</Grid>
								<Grid item md={4} xs={6}>
									<h3>{totals[0].sale} Sales</h3>
								</Grid>
								<Grid item md={4} xs={6}>
									<h3>{totals[0].completed} Completed</h3>
								</Grid>
								<Grid item md={6} xs={6}>
									<h3>Campaign Budget: {stateContext.campaigns[stateContext.selectedCampaign] ? toCurrency(stateContext.campaigns[stateContext.selectedCampaign].budget) : '$0.00'}</h3>
								</Grid>
								<Grid item md={6} xs={6}>
									<h3>Total Value: {totals[0].value}</h3>
								</Grid>
								<Grid item md={6} xs={6}>
									<h3>Revenue Gain: {totals[0].revenue}</h3>
								</Grid>
								<Grid item md={6} xs={6}>
									<h3>Campaign ROI: {totals[0].roi}</h3>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>
			}
		</CardContent>
  );
}

export default React.memo(ReportTotals);