import React, { useState, useEffect, useContext } from 'react';
import StoreContext from '../contexts/storeContext'

import { useHistory } from "react-router-dom";

import clsx from 'clsx';

import axios from 'axios';

import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Button, Divider, FormGroup, FormControlLabel, Switch, AppBar, Tabs, Tab } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';

import { version, api } from '../util/utils'

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});



// Begin component
const SuperAdminConsole = (props) => {

	const [stateContext, setStateContext] = useContext(StoreContext)
	const [uiLoading, setUiLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState(stateContext.company)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [country, setCountry] = useState('')
  const [username, setUsername] = useState('')
  const [role, setRole] = useState('user')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState([])
	const [errorMsg, setErrorMsg] = useState('')
	const [keyName, setKeyName] = useState('')
	const [keyValue, setKeyValue] = useState('')

  const [loading, setLoading] = useState(false)
  let history = useHistory()
	const isDev = stateContext.userData.role === "developer"
  
  const handleChange = (event) => {
		let name = event.target.name , value = event.target.value;
		switch(name) {
      case 'keyName':
        setKeyName(value);
        break;
      case 'keyValue':
        setKeyValue(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'username':
        setUsername(value);
        break;
			case 'email':
				setEmail(value);
        break;
      case 'role':
        setRole(value);
        break;
			case 'password':
				setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
			default:
				break;
		}
	}

	const handleSubmit = (event) => {
		event.preventDefault()
		console.log(companyName)
		let leadList = {}
		axios
			.get(`${api}/leads/0/33455462399/${companyName}`)
			.then((res) => {
				setUiLoading(false)
				leadList = res.data
			})
			.catch((error) => {
				isDev && console.log('didnt make it')
				if(error.response.status === 403) {
					history.push('/login')
				}
				console.log(error);
				setErrorMsg('Error in retrieving the data')   
			})
			.then((response) => {
				for (const [key, value] of Object.entries(leadList)) {
					// console.log(`${key}: ${value.leadId}`);
					let leadUpdates = {[value.leadId]: {[keyName]: keyValue}}
					axios
						.put(`${api}/leads`, leadUpdates)
						.then((response) => {
							isDev && console.log('updated leads sent to server')
						})
						.catch((error) => {
							isDev && console.log(error)
						})
				}
			})
	}

	// Begin UX return
	
	const { classes, ...rest } = props;
	return (
		<main className={classes.content}>
			<div className={classes.toolbar} />
			<Card >
				<CardContent container>
					<h1>Admin Console</h1>
					<p>This page contains admin-exclusive controls</p>
				</CardContent>
			</Card>
			{ isDev &&
				<Card>
				<CssBaseline />
				<div className='newAccountForm'>
					<h3>Bulk Add or Replace Key Value Pairs in Leads by Company</h3>
					<form className={props.form} noValidate>
						<Grid container spacing={2}>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="companyName"
									label="Company Name"
									name="companyName"
									autoComplete="companyName"
									helperText={errors.companyName}
									error={errors.companyName ? true : false}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="keyName"
									label="Key"
									name="keyName"
									autoComplete="keyName"
									helperText={errors.keyName}
									error={errors.keyName ? true : false}
									onChange={handleChange}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									variant="outlined"
									required
									fullWidth
									id="keyValue"
									label="Value"
									name="keyValue"
									autoComplete="keyValue"
									helperText={errors.keyValue}
									error={errors.keyValue ? true : false}
									onChange={handleChange}
								/>
							</Grid>
						</Grid>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
							className="reportButton submitButton"
							onClick={handleSubmit}
							disabled={loading || !companyName || !keyName || !keyValue }
						>
							Update Leads
							{loading && <CircularProgress size={30} className={props.progess} />}
						</Button>
					</form>
				</div>
			</Card>
			}
			
		</main>
	)
};

export default withStyles(styles)(SuperAdminConsole);