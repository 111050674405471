// account.js

import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";

import analytics from '../util/analytics'
import { api } from '../util/utils'
import StoreContext from '../contexts/storeContext'

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Card, CardActions, CardContent, Divider, Button, Grid, TextField } from '@material-ui/core';

import clsx from 'clsx';

import axios from 'axios';
import { authMiddleWare } from '../util/auth';

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});

const Account = (props) => {
  
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [username, setUsername] = useState('')
  const [country, setCountry] = useState('')
  const [role, setRole] = useState('user')
  const [profilePicture, setProfilePicture] = useState('')
  const [uiLoading, setUiLoading] = useState(false)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [imageError, setImageError] = useState('')
  const [image, setImage] = useState('')
  const [content, setContent] = useState('')
  const [errorMsg, setErrorMsg] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [userId, setUserId] = useState('')
  const [stateContext, setStateContext] = useContext(StoreContext)
  let history = useHistory()

  const isDev = stateContext.userData.role === "developer"

  useEffect(() => {
    if (stateContext.userData) {
      setFirstName(stateContext.userData.firstName)
      setLastName(stateContext.userData.lastName)
      setEmail(stateContext.userData.email)
      setCompanyName(stateContext.userData.companyName)
      setPhoneNumber(stateContext.userData.phoneNumber)
      setCountry(stateContext.userData.country)
      setUsername(stateContext.userData.username)
      setRole(stateContext.userData.role)
      setProfilePicture(stateContext.userData.imageUrl)
      setCreatedAt(stateContext.userData.createdAt)
      setUserId(stateContext.userData.userId)
    }
  },[stateContext.userData])
  
	const handleChange = (event) => {
		let name = event.target.name , value = event.target.value;
		switch(name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'username':
        setUsername(value);
        break;
      case 'uiLoading': // probably not necessary
        setUiLoading(value);
        break;
      case 'role':
        setRole(value);
        break;
			default:
				break;
		}
	}

	const handleImageChange = (event) => {
			setImage(event.target.files[0])
	};

	const profilePictureHandler = (event) => {
		event.preventDefault();
		setUiLoading(true)
		authMiddleWare(history);
		const authToken = localStorage.getItem('AuthToken');
		let form_data = new FormData();
		form_data.append('image', image);
		form_data.append('content', content);
		axios.defaults.headers.common = { Authorization: `${authToken}` };
    analytics.logEvent(`update_profile_photo_completed`, {page_title: 'Manage Account', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName})
		axios
			.post(`${api}/user/image`, form_data, {
				headers: {
					'content-type': 'multipart/form-data'
				}
			})
			.then(() => {
				window.location.reload();
			})
			.catch((error) => {
				if (error.response.status === 403) {
					history.push('/login');
				}
				isDev && console.log(error);
        setUiLoading(false)
        setImageError('Error in posting the data')
			});
	};
  
	const updateFormValues = (event) => {
		event.preventDefault();
		setButtonLoading(true);
		authMiddleWare(history);
		const authToken = localStorage.getItem('AuthToken');
		axios.defaults.headers.common = { Authorization: `${authToken}` };
		const formRequest = {
			firstName: firstName,
			lastName: lastName,
      country: country,
      phoneNumber: phoneNumber,
      username: stateContext.userData.username
		};
		axios
			.post(`${api}/user`, formRequest)
			.then(() => {
        setButtonLoading(false);
        let updatedUser = {
          companyName,
          country,
          createdAt,
          email,
          firstName,
          lastName,
          phoneNumber,
          profilePicture,
          role,
          userId,
          username
        }
        analytics.logEvent(`update_account_completed`, {accountUpdateData: JSON.stringify(formRequest), page_title: 'Manage Account', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName})
        setStateContext({ ...stateContext, userData: updatedUser })
			})
			.catch((error) => {
				if (error.response.status === 403) {
					history.push('/login');
				}
				isDev && console.log(error);
				setButtonLoading(false)
			});
	};

  const { classes, ...rest } = props;
  if (uiLoading === true) {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {uiLoading && <CircularProgress size={150} className={classes.uiProgess} />}
      </main>
    );
  } else {
    return (
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Card {...rest} className={clsx(classes.root, classes)}>
          <CardContent>
            <div className={classes.details}>
              <div>
                <Typography className={classes.locationText} gutterBottom variant="h4">
                  {firstName} {lastName}
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<CloudUploadIcon />}
                  className={classes.uploadButton}
                  onClick={profilePictureHandler}
                >
                  Upload Photo
                </Button>
                <input type="file" onChange={handleImageChange} />

                {imageError ? (
                  <div className={classes.customError}>
                    {' '}
                    Wrong Image Format || Supported Format are PNG and JPG
                  </div>
                ) : (
                  false
                )}
              </div>
            </div>
            <div className={classes.progress} />
          </CardContent>
          <Divider />
        </Card>

        <br />
        <Card {...rest} className={clsx(classes.root, classes)}>
          <form autoComplete="off" noValidate>
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="First name"
                    margin="dense"
                    name="firstName"
                    variant="outlined"
                    value={firstName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Last name"
                    margin="dense"
                    name="lastName"
                    variant="outlined"
                    value={lastName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    margin="dense"
                    name="email"
                    variant="outlined"
                    disabled={true}
                    value={email}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    margin="dense"
                    name="phoneNumber"
                    variant="outlined"
                    value={phoneNumber}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="User Name"
                    margin="dense"
                    name="userHandle"
                    disabled={true}
                    variant="outlined"
                    value={username}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Country"
                    margin="dense"
                    name="country"
                    variant="outlined"
                    value={country}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Company Name"
                    margin="dense"
                    name="companyName"
                    disabled={true}
                    variant="outlined"
                    value={companyName}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Account Type"
                    margin="dense"
                    name="role"
                    disabled={true}
                    variant="outlined"
                    value={role}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions />
          </form>
        </Card>
        <Button
          color="primary"
          variant="contained"
          type="submit"
          className={classes.submitButton}
          onClick={updateFormValues}
          disabled={
            buttonLoading ||
            !firstName ||
            !lastName ||
            !country ||
            !phoneNumber
          }
        >
          Save details
          {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
        </Button>
      </main>
    );
  }
}

export default withStyles(styles)(Account);
