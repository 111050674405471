import firebase from "firebase";
import "firebase/analytics";

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCoP5OZnbxLRzDU1_uJwzFrtk07HK8xOAU",
  authDomain: "idyllic-physics-297416.firebaseapp.com",
  projectId: "idyllic-physics-297416",
  storageBucket: "idyllic-physics-297416.appspot.com",
  messagingSenderId: "463917537410",
  appId: "1:463917537410:web:aa3503604bcc39445ffc9b",
  measurementId: "G-79D3514MKM"
};

firebase.initializeApp(firebaseConfig)
const analytics = firebase.analytics()

// if (typeof window !== 'undefined' && !firebase.apps.length) {
//   
//   if ('measurementId' in firebaseConfig) firebase.analytics()
// }

export default analytics