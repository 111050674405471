import React, { useState, useContext } from 'react';
import StoreContext from '../contexts/storeContext'
import { useHistory } from "react-router-dom";

import analytics from '../util/analytics'
import { fromCurrency, toCurrency, toUnix, toDatePicker, api } from '../util/utils'
import { authMiddleWare } from '../util/auth';

import { Button, CardContent, Grid, TextField, CardActions, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  formControl: {
		margin: 0,
		marginTop: '8px',
		marginBottom: '4px',
    minWidth: '100%',
    maxWidth: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const LeadCreate = (props) => {
	let now = Math.floor(Date.now() / 1000)
	const [buttonLoading, setButtonLoading] = useState(false)
	const [visibleToggleState, setVisibleToggleState] = useState(props.visible)
	const [stateContext, setStateContext] = useContext(StoreContext)
	const [leadName, setLeadName] = useState('')
	const [leadCity, setLeadCity] = useState('')
	// const [leadCompanyName, setLeadCompanyName] = useState(stateContext.userData.companyName ? stateContext.userData.companyName : '')
	const [leadCompleted, setLeadCompleted] = useState('n/a')
	const [leadCountry, setLeadCountry] = useState('US')
	const [leadEmail, setLeadEmail] = useState('')
	const [leadKeywords, setLeadKeywords] = useState(`keyword 1, keyword 2`)
	const [leadSource, setLeadSource] = useState('')
	const [leadLandingPage, setLeadLandingPage] = useState('')
	const [leadNote, setLeadNote] = useState('')
	const [leadType, setLeadType] = useState('call')
	const [leadContent, setLeadContent] = useState('')
	const [leadPhoneNumber, setLeadPhoneNumber] = useState('')
	const [leadQualified, setLeadQualified] = useState('pending')
	const [leadStartTime, setLeadStartTime] = useState(now)
	const [leadState, setLeadState] = useState('')
	const [leadSale, setLeadSale] = useState('pending')
	const [leadValue, setLeadValue] = useState('$0.00')
	let history = useHistory()
	
	const isDev = stateContext.userData.role === "developer"
	const classes = useStyles();
	const postCreateLead = (event) => {
		event.preventDefault();
		authMiddleWare(history);
		isDev && console.log('sending new lead to servers')
		setButtonLoading(true);

		let keywordArr = ''
		if (leadKeywords !== 'keyword 1, keyword 2' ) {
			keywordArr = leadKeywords;
		} else keywordArr = ''

		const now = Math.floor(Date.now() / 1000)

		const formRequest = {
			createdAt: now,
			createdBy: stateContext.userData.username,
			companyName: stateContext.company[0],
			companyId: stateContext.company[1],
			completed: leadCompleted,
			country: leadCountry,
			email: leadEmail,
			keywords: keywordArr,
			leadSource: 'manual',
			name: leadName,
			note: leadNote,
			landingPage: leadLandingPage,
			type: leadType,
			content: leadContent,
			phoneNumber: leadPhoneNumber,
			qualified: leadQualified,
			startTime: toUnix(leadStartTime).toString(),
			city: leadCity,
			state: leadState,
			source: leadSource,
			sale: leadSale,
			value: fromCurrency(leadValue),
		};
		isDev && console.log(formRequest)
		axios
			.post(`${api}/leads/`, formRequest)
			.then((response) => {
				isDev && console.log(response.data)
				stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`create_lead_completed`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
				let leadsList = [...stateContext.leads]
				let fixedLead = response.data
				fixedLead.date = fixedLead.startTime
				fixedLead.phone = fixedLead.phoneNumber
				fixedLead.value = toCurrency(fixedLead.value)
				leadsList.push(fixedLead)
				isDev && console.log(leadsList)
				setStateContext({ ...stateContext, leads: [...leadsList] })
				setButtonLoading(false);
				isDev && console.log('lead added to server')
				props.onToggleView(false)
				setVisibleToggleState(false)
			})

			.catch((error) => {
				if (error.response.status === 403) {
					history.push('/login');
				}
				isDev && console.log(error);
				setButtonLoading(false)
			});
	};

	const cancelCreate = (event) => {
		event.preventDefault();
		isDev && console.log('cancelling create lead')
		props.onToggleView(false)
		setVisibleToggleState(false)
	}

	const handleLeadChange = (event) => {
		let name = event.target.name, value = event.target.value;
		switch(name) {
			case 'leadName':
				setLeadName(value);
				break;
			case 'leadCity':
				setLeadCity(value);
				break;
			// case 'leadCompanyName':
			// 	setLeadCompanyName(value);
			// 	break;
			case 'leadCompleted':
				setLeadCompleted(value);
				break;
			case 'leadCountry':
				setLeadCountry(value);
				break;
			case 'leadEmail':
				setLeadEmail(value);
				break;
			case 'leadKeywords':
				setLeadKeywords(value);
				break;
			case 'leadSource':
				setLeadSource(value);
				break;
			case 'leadNote':
				setLeadNote(value);
				break;
			case 'leadLandingPage':
				setLeadLandingPage(value);
				break;
			case 'leadType' :
				setLeadType(value);
				break;
			case 'leadContent':
				setLeadContent(value);
				break;
			case 'leadPhoneNumber':
				setLeadPhoneNumber(value);
				break;
			case 'leadQualified':
				setLeadQualified(value);
				break;
			case 'leadStartTime':
				setLeadStartTime(toUnix(value));
				break;
			case 'leadState':
				setLeadState(value);
				break;
			case 'leadSale':
				setLeadSale(value);
				break;
			case 'leadValue':
				setLeadValue(value);
				break;
			default:
				break;
		}
	}
  
  return (
    <div className={props.visible ? 'leadCreate visible' : 'leadCreate hidden'}>
			<form autoComplete="off" noValidate>
				<CardContent>
					<h2>Create Lead</h2>
					<div className="detailsForm">
						<Grid container spacing={3}>
							<Grid item md={2} xs={12}>
								<TextField
									fullWidth
									label="Date"
									type="date"
									margin="dense"
									name="leadStartTime"
									variant="outlined"
									value={toDatePicker(leadStartTime)}
									onChange={handleLeadChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item md={2} xs={12}>
								<FormControl fillWidth variant="outlined" className={classes.formControl}>
									<InputLabel>Type</InputLabel>
									<Select
										fullWidth
										margin="dense"
										variant="outlined"
										name="leadType"
										value={leadType}
										onChange={handleLeadChange}
									>
										<MenuItem value={"call"}>Call</MenuItem>
										<MenuItem value={"form"}>Form</MenuItem>
										<MenuItem value={"other"}>Other</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									fullWidth
									label="Name"
									margin="dense"
									name="leadName"
									variant="outlined"
									value={leadName}
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									fullWidth
									label="Source"
									margin="dense"
									name="leadSource"
									variant="outlined"
									value={leadSource}
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									fullWidth
									multiline
									label="Landing Page"
									margin="dense"
									name="leadLandingPage"
									variant="outlined"
									value={leadLandingPage}
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									fullWidth
									label="Email"
									margin="dense"
									name="leadEmail"
									variant="outlined"
									value={leadEmail}
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={4} xs={12}>
								<TextField
									fullWidth
									label="Phone Number"
									margin="dense"
									name="leadPhoneNumber"
									variant="outlined"
									value={leadPhoneNumber}
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={2} xs={12}>
								<TextField
									fullWidth
									label="City"
									margin="dense"
									name="leadCity"
									variant="outlined"
									value={ leadCity }
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={2} xs={12}>
								<TextField
									fullWidth
									label="State"
									margin="dense"
									name="leadState"
									variant="outlined"
									value={ leadState }
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={2} xs={12}>
								<TextField
									fullWidth
									label="Country"
									margin="dense"
									name="leadCountry"
									variant="outlined"
									value={ leadCountry }
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Keywords"
									margin="dense"
									name="leadKeywords"
									variant="outlined"
									value={ leadKeywords }
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={3} xs={12}>
								<FormControl fillWidth variant="outlined" className={classes.formControl}>
									<InputLabel>Qualified</InputLabel>
									<Select
										fullWidth
										margin="dense"
										variant="outlined"
										name="leadQualified"
										value={leadQualified}
										onChange={handleLeadChange}
									>
										<MenuItem value={"yes"}>yes</MenuItem>
										<MenuItem value={"no"}>no</MenuItem>
										<MenuItem value={"pending"}>pending</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={3} xs={12}>
								<FormControl fillWidth variant="outlined" className={classes.formControl}>
									<InputLabel>Sale</InputLabel>
									<Select
										fullWidth
										margin="dense"
										variant="outlined"
										name="leadSale"
										value={leadSale}
										onChange={handleLeadChange}
									>
										<MenuItem value={"yes"}>yes</MenuItem>
										<MenuItem value={"no"}>no</MenuItem>
										<MenuItem value={"pending"}>pending</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={3} xs={12}>
								<TextField
									fullWidth
									label="Value"
									margin="dense"
									name="leadValue"
									variant="outlined"
									value={ leadValue }
									onBlur= {() => setLeadValue(toCurrency(fromCurrency(leadValue))) }
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={3} xs={12}>
								<FormControl fillWidth variant="outlined" className={classes.formControl}>
									<InputLabel>Completed</InputLabel>
									<Select
										fullWidth
										margin="dense"
										variant="outlined"
										name="leadCompleted"
										value={leadCompleted}
										onChange={handleLeadChange}
									>
										<MenuItem value={"yes"}>yes</MenuItem>
										<MenuItem value={"no"}>no</MenuItem>
										<MenuItem value={"n/a"}>n/a</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextField
									fullWidth
									multiline
									label="Content"
									margin="dense"
									name="leadContent"
									variant="outlined"
									value={leadContent}
									onChange={handleLeadChange}
								/>
							</Grid>
							<Grid item md={12} xs={12}>
								<TextField
									fullWidth
									multiline
									label="Notes"
									margin="dense"
									name="leadNote"
									variant="outlined"
									value={leadNote}
									onChange={handleLeadChange}
								/>
							</Grid>
						</Grid>
					</div>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={postCreateLead}
						disabled={
							buttonLoading ||
							!leadStartTime ||
							!leadType ||
							!leadName
						}
					>
						Create Lead
						{buttonLoading && <CircularProgress size={30} />}
					</Button>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={cancelCreate}
						// disabled={
						// 	( isUpdated == false )
						// }
					>
						Cancel Create
						{buttonLoading && <CircularProgress size={30} />}
					</Button>
				</CardContent>
				<CardActions />
			</form>
		</div>
  );
}

export default React.memo(LeadCreate);