// login.js

// Material UI components
import React, { useState } from 'react';

import { api } from '../util/utils'


import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	}
});


const ResetPassword = (props) => {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [errors, setErrors] = useState([])
	const [loading,  setLoading] = useState(false)
	const [message, setMessage] = useState(`Enter your email below and we'll send you a link to reset your password.`)
	
	const handleChange = (event) => {
		let name = event.target.name , value = event.target.value;
		switch(name) {
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			default:
				break;
		}
	}
	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		const userData = {
			email: email,
			password: password
		};
		axios
			.post(`${api}/resetPassword`, userData)
			.then((response) => {
				console.log(response)
				setLoading(false)
				setEmail('')
				setMessage('Your request to reset your password has been received. Please check your mail to find your instructions to change your password.')
			})
			.catch((error) => {				
				setErrors(error.response.data)
				setLoading(false)
			});
	}

	return (
		<Container component="main" id="login-main" maxWidth="xs">
			<CssBaseline />
			<div className={props.paper}>
				<Avatar className={props.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Password Reset
				</Typography>
				<p>{message}</p>
				<form className={props.form} noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						helperText={errors.email}
						error={errors.email ? true : false}
						onChange={handleChange}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={props.submit}
						onClick={handleSubmit}
						disabled={loading || !email}
					>
						Send Reset Request
						{loading && <CircularProgress size={30} className={props.progess} />}
					</Button>
					{/* <Grid container>
						<Grid item>
							<Link href="signup" variant="body2">
								{"Don't have an account? Sign Up"}
							</Link>
						</Grid>
					</Grid> */}
					{errors.general && (
						<Typography variant="body2" className={props.customError}>
							{errors.general}
						</Typography>
					)}
				</form>
				<Grid container>
					<Grid item>
						<Link href="login" variant="body2">
							{"Return to login page"}
						</Link>
					</Grid>
				</Grid>
			</div>
		</Container>
	);
}

export default withStyles(styles)(ResetPassword);
