// login.js

// Material UI components
import React, { useState } from 'react';

import analytics from '../util/analytics'
import { api } from '../util/utils'

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%',
		marginTop: theme.spacing(1)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	progess: {
		position: 'absolute'
	}
});

// class login extends Component {
const Login = (props) => {

	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const [errors, setErrors] = useState([])
	const [loading,  setLoading] = useState(false)
	
	const handleChange = (event) => {
		let name = event.target.name , value = event.target.value;
		switch(name) {
			case 'email':
				setEmail(value);
				break;
			case 'password':
				setPassword(value);
				break;
			default:
				break;
		}
	}
	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true);
		const userData = {
			email: email,
			password: password
		};
		axios
			.post(`${api}/login`, userData)
			.then((response) => {
				localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
				// analytics.logEvent('login_user', {email: userData.email})
				setLoading(false)
				props.history.push('/');
			})
			.catch((error) => {				
				setErrors(error)
				setLoading(false)
			});
	}

	return (
		<Container component="main" id="login-main" maxWidth="xs">
			<CssBaseline />
			<div className={props.paper}>
				<Avatar className={props.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography component="h1" variant="h5">
					Login
				</Typography>
				<form className={props.form} noValidate>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						id="email"
						label="Email Address"
						name="email"
						autoComplete="email"
						autoFocus
						helperText={errors.email}
						error={errors.email ? true : false}
						onChange={handleChange}
					/>
					<TextField
						variant="outlined"
						margin="normal"
						required
						fullWidth
						name="password"
						label="Password"
						type="password"
						id="password"
						autoComplete="current-password"
						helperText={errors.password}
						error={errors.password ? true : false}
						onChange={handleChange}
					/>
					<Button
						type="submit"
						fullWidth
						variant="contained"
						color="primary"
						className={props.submit}
						onClick={handleSubmit}
						disabled={loading || !email || !password}
					>
						Sign In
						{loading && <CircularProgress size={30} className={props.progess} />}
					</Button>
					<Grid container>
						<Grid item>
							<Link href="reset-password" variant="body2">
								{"Forgot Your Password?"}
							</Link>
						</Grid>
					</Grid>
					{errors.general && (
						<Typography variant="body2" className={props.customError}>
							{errors.general}
						</Typography>
					)}
				</form>
			</div>
		</Container>
	);
}

export default withStyles(styles)(Login);
