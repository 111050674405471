// create-account.js

import React, { useState, useEffect, useContext } from 'react';
import StoreContext from '../contexts/storeContext'

import { useHistory } from "react-router-dom";

import analytics from '../util/analytics'
import { api } from '../util/utils'
import { authMiddleWare } from '../util/auth';

import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';

import axios from 'axios';

const styles = (theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: theme.palette.secondary.main
	},
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(3)
	},
	submit: {
		margin: theme.spacing(3, 0, 2)
	},
	progress: {
		position: 'absolute'
	}
});

// class signup extends Component {
const Signup = (props) => {
  const [stateContext, setStateContext] = useContext(StoreContext)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [companyName, setCompanyName] = useState(stateContext.company[0])
  const [companyId, setCompanyId] = useState(stateContext.company[1])
  const [phoneNumber, setPhoneNumber] = useState('')
  const [country, setCountry] = useState('')
  const [username, setUsername] = useState('')
  const [role, setRole] = useState('user')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [errors, setErrors] = useState([])
  const [permissions, setPermissions] = useState({
    viewCompanyPage: false,
    createUserAccounts: false,
    deleteUserAccounts: false,
    editUserAccounts: false,
    createCampaigns: false,
    editCampaignDetails: false,
    deleteCampaigns: false,
    createNewLeads: false,
    editLeads: false,
    importLeads: false,
    deleteLeads: false,
  })
  const [loading, setLoading] = useState(false)
  let history = useHistory()
  
  const handleChange = (event) => {
		let name = event.target.name , value = event.target.value, checked = event.target.checked;
		switch(name) {
      case 'firstName':
        setFirstName(value);
        break;
      case 'lastName':
        setLastName(value);
        break;
      case 'companyName':
        setCompanyName(value);
        break;
      case 'companyId':
        setCompanyId(value);
        break;
      case 'phoneNumber':
        setPhoneNumber(value);
        break;
      case 'country':
        setCountry(value);
        break;
      case 'username':
        setUsername(value);
        break;
			case 'email':
				setEmail(value);
        break;
      case 'role':
        setRole(value);
        break;
			case 'password':
				setPassword(value);
        break;
      case 'confirmPassword':
        setConfirmPassword(value);
        break;
      case 'viewCompanyPage':
        setPermissions({...permissions, viewCompanyPage: checked});
        break;
      case 'createUserAccounts':
        setPermissions({...permissions, createUserAccounts: checked});
        break;
        case 'deleteUserAccounts':
          setPermissions({...permissions, deleteUserAccounts: checked});
          break;
      case 'editUserAccounts':
        setPermissions({...permissions, editUserAccounts: checked});
        break;
      case 'createCampaigns':
        setPermissions({...permissions, createCampaigns: checked});
        break;
      case 'editCampaignDetails':
        setPermissions({...permissions, editCampaignDetails: checked});
        break;
      case 'deleteCampaigns':
        setPermissions({...permissions, deleteCampaigns: checked});
        break;
      case 'createNewLeads':
        setPermissions({...permissions, createNewLeads: checked});
        break;
      case 'editLeads':
        setPermissions({...permissions, editLeads: checked});
        break;
      case 'importLeads':
        setPermissions({...permissions, importLeads: checked});
        break;
      case 'deleteLeads':
        setPermissions({...permissions, deleteLeads: checked});
        break;
			default:
				break;
		}
	}
  useEffect(() => {setCompanyName(stateContext.company[0]); setCompanyId(stateContext.company[1])}, [stateContext.company])
  const handleSubmit = (event) => {
    event.preventDefault();
    authMiddleWare(history);
		setLoading(true)
		const newUserData = {
			firstName,
      lastName,
      companyId,
      companyName,
			phoneNumber,
			country,
			username,
      email,
      role,
			password,
			confirmPassword,
      permissions
		};
		axios
			.post(`${api}/signup`, newUserData)
			.then((response) => {
				setLoading(false)
        stateContext.company && analytics.logEvent(`create_account_completed`, {page_title: 'Manage Company', activeCompany: stateContext.company[0], newUsername: username, username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName})
        props.submitReset()
			})
			.catch((error) => {
        setErrors(error.response.data)
        setLoading(false)
        if (error.response.status === 403) {
					history.push('/login');
				}
			});
	};

  return (
    <Card>
      <CssBaseline />
      <div className='newAccountForm'>
        <form className={props.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                name="firstName"
                autoComplete="firstName"
                helperText={errors.firstName}
                error={errors.firstName ? true : false}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lastName"
                helperText={errors.lastName}
                error={errors.lastName ? true : false}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="username"
                label="User Name"
                name="username"
                autoComplete="username"
                helperText={errors.username}
                error={errors.username ? true : false}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="phoneNumber"
                label="Phone Number"
                name="phoneNumber"
                autoComplete="phoneNumber"
                pattern="[7-9]{1}[0-9]{9}"
                helperText={errors.phoneNumber}
                error={errors.phoneNumber ? true : false}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                helperText={errors.email}
                error={errors.email ? true : false}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="country"
                label="Country"
                name="country"
                autoComplete="country"
                helperText={errors.country}
                error={errors.country ? true : false}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                helperText={errors.password}
                error={errors.password ? true : false}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="Confirm Password"
                type="password"
                id="confirmPassword"
                autoComplete="current-password"
                onChange={handleChange}
              />
            </Grid>
            {(stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer') && 
              <Grid item xs={12} sm={6}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Role</InputLabel>
                <Select
                  name="role"
                  onChange={handleChange}
                  displayEmpty
                  variant="outlined"
                  required
                  fullWidth
                  defaultValue={"user"}
                  disabled={(props.role !== "admin" && props.role !== "developer")}
                  value={role}
                  helperText={errors.role}
                  error={errors.role ? true : false}
                >
                  {(props.role === "admin" || props.role === 'developer') && <MenuItem value={"user"}>User</MenuItem>}
                  {(props.role === "admin" || props.role === 'developer') && <MenuItem value={"owner"}>Owner</MenuItem>}
                  {(props.role === 'developer') && <MenuItem value={"admin"}>Admin</MenuItem>}
                </Select>
              </FormControl>
            </Grid>
            }
            <Grid item xs={12}>
             <div className='newUserFormChecks'>
								<FormControl>
									<div>
										<FormLabel>Account Permissions</FormLabel>
									</div>
									<FormGroup>
										<FormControlLabel 
											control={<Checkbox checked={permissions.viewCompanyPage} onChange={handleChange} name="viewCompanyPage" />}
											label="View Company Page"
										/>
										<FormControlLabel 
											control={<Checkbox checked={permissions.createUserAccounts} onChange={handleChange} name="createUserAccounts" />}
											label="Create User Accounts"
										/>
										<FormControlLabel 
											control={<Checkbox checked={permissions.editUserAccounts} onChange={handleChange} name="editUserAccounts" />}
											label="Edit User Accounts"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.deleteUserAccounts} onChange={handleChange} name="deleteUserAccounts" />}
											label="Delete User Accounts"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.createCampaigns} onChange={handleChange} name="createCampaigns" />}
											label="Create Campaigns"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.editCampaignDetails} onChange={handleChange} name="editCampaignDetails" />}
											label="Edit Campaign Details"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.deleteCampaigns} onChange={handleChange} name="deleteCampaigns" />}
											label="Delete Campaigns"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.createNewLeads} onChange={handleChange} name="createNewLeads" />}
											label="Create New Leads"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.importLeads} onChange={handleChange} name="importLeads" />}
											label="Import Leads"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.editLeads} onChange={handleChange} name="editLeads" />}
											label="Edit Leads"
										/>
                    <FormControlLabel 
											control={<Checkbox checked={permissions.deleteLeads} onChange={handleChange} name="deleteLeads" />}
											label="Delete Leads"
										/>
									</FormGroup>
								</FormControl>
              </div>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="reportButton submitButton"
            onClick={handleSubmit}
                          disabled={loading || 
                              !email || 
                              !password ||
                              !firstName || 
                              !lastName ||
                              !companyName ||
                              !companyId ||
                              !country || 
                              !username || 
                              !role ||
                              !phoneNumber}
          >
            Create New Account
            {loading && <CircularProgress size={30} className={props.progess} />}
          </Button>
        </form>
      </div>
    </Card>
  );
}

export default withStyles(styles)(Signup);
