// Imports
const excelToJson = require('convert-excel-to-json')

const dayjs = require('dayjs');
const advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const { fromCurrency } = require('./utils') 

const leadFields = [
  'city',
  'formUrl',
  'referrer',
  'landingPage',
  'type',
  'content',
  'companyId',
  'companyName',
  'completed',
  'country',
  'createdAt',
  'createdBy',
  'email',
  'keywords',
  'leadSource',
  'recordingUrl',
  'name',
  'note',
  'phoneNumber',
  'qualified',
  'sale',
  'source',
  'startTime',
  'state',
  'value'
]

export const bulkImportXlsx = (docPath, companyId, companyName, createdBy) => {
  
  const now = Math.floor(Date.now() / 1000)
  let qualityCheck = true;
  let xlsData = excelToJson({
    source: docPath, 
    header:{rows: 1},
    columnToKey: {
      '*': `{{columnHeader}}`
    }
  })
  let xlsTopLvlKey = Object.keys(xlsData)
  xlsData[xlsTopLvlKey[0]].map((lead) => {
    for (const k in lead) {
      if (k === "Phone Number") {
        lead["phoneNumber"] = lead[k];
          delete lead[k];
      } else if (k === "Date") {
        lead.startTime = dayjs(lead[k]).format('X');
          delete lead[k];
      } else if (k === "Keywords") {
        lead.keywords = lead[k];
          delete lead[k];
      } else if (k === "Value") {
        lead.value = fromCurrency(lead[k]);
          delete lead[k];
      } else if (k === "Recording Url") {
        lead.recordingUrl = lead[k];
          delete lead[k];
      } else if (k === "Form Url") {
        lead.formUrl = lead[k];
          delete lead[k];
      } else if (k === "Landing Page") {
        lead.landingPage = lead[k];
          delete lead[k];
      } else {
        lead[k.toLowerCase()] = lead[k];
        delete lead[k]
      }
    }
    for (const k in lead) {
      if (!leadFields.includes(k)){
        qualityCheck = false;
        return 'foo'
      }
    }
    if (!lead.startTime && !lead.date) lead.startTime = now
    if (!lead.name) lead.name = ''
    if (!lead.source) lead.source = ''
    if (!lead.city) lead.city = ''
    if (!lead.completed) lead.completed = ''
    if (!lead.country) lead.country = ''
    if (!lead.email) lead.email = ''
    if (!lead.keywords) lead.keywords = ''
    if (!lead.note) lead.note = ''
    if (!lead.phoneNumber) lead.phoneNumber = ''
    if (!lead.qualified) lead.qualified = 'pending'
    if (!lead.state) lead.state = ''
    if (!lead.sale) lead.sale = ''
    if (!lead.type) lead.type = 'call'
    if (!lead.content) lead.content = ''
    if (!lead.value) lead.value = 0
    lead.leadSource = 'manual'
    lead.companyId = companyId
    lead.companyName = companyName
    lead.createdBy = createdBy
    lead.createdAt = now
  })
  if (qualityCheck === false){
    alert('imported sheet includes incorrect fields. Please correct your headers and upload again')
    return ''
  } else {
    let output = {leads: xlsData[xlsTopLvlKey] }
    // console.log(output)
    return output
  }
}

export const bulkImportCsv = (docPath, companyId, companyName, createdBy) => {
  
  const now = Math.floor(Date.now() / 1000)
  let qualityCheck = true;
  // begin conversion of csv to json
  let array = docPath.split("\r\n"); //split into array of headers plus rows
  let result = []; 
  let headers = array[0].split(",") // split headers into its own array
  for (let i = 1; i < array.length; i++) { // for remaining rows - convert to array
    let obj = {} 
    
    let str = array[i] 
    let s = ''
    
    let flag = 0 
    for (let ch of str) { 
      if (ch === '"' && flag === 0) { 
        flag = 1 
      } 
      else if (ch === '"' && flag == 1) flag = 0 
      if (ch === ',' && flag === 0) ch = '|'
      if (ch !== '"') s += ch 
    } 

    let properties = s.split("|") 

    for (let j in headers) { 
      if (properties[j].includes(",")) { 
        obj[headers[j]] = properties[j] 
          .split(",").map(item => item.trim()) 
      } 
      else obj[headers[j]] = properties[j] 
    } 
    result.push(obj) 
  } 
  // Begin json fixing to appropriate database format
  result.map((lead) => {
    for (const k in lead) {
      if (k === "Phone Number") {
        lead["phoneNumber"] = lead[k];
          delete lead[k];
      } else if (k === "Date") {
        lead.startTime = dayjs(lead[k]).format('X');
          delete lead[k];
      } else if (k === "Keywords") {
        lead.keywords = lead.Keywords;
          delete lead[k];
      } else if (k === "Value") {
        lead.value = fromCurrency(lead[k]);
          delete lead[k];
      } else if (k === "Recording Url") {
        lead.recordingUrl = lead[k];
          delete lead[k];
      } else if (k === "Form Url") {
        lead.formUrl = lead[k];
          delete lead[k];
      } else if (k === "Landing Page") {
        lead.landingPage = lead[k];
          delete lead[k];
      } else {
        lead[k.toLowerCase()] = lead[k];
        delete lead[k]
      }
    }
    for (const k in lead) {
      if (!leadFields.includes(k)){
        qualityCheck = false;
        return 'foo'
      }
    }
    if (!lead.startTime && !lead.date) lead.startTime = now
    if (!lead.name) lead.name = ''
    if (!lead.source) lead.source = ''
    if (!lead.city) lead.city = ''
    if (!lead.completed) lead.completed = ''
    if (!lead.country) lead.country = ''
    if (!lead.email) lead.email = ''
    if (!lead.keywords) lead.keywords = ''
    if (!lead.note) lead.note = ''
    if (!lead.phoneNumber) lead.phoneNumber = ''
    if (!lead.qualified) lead.qualified = 'pending'
    if (!lead.state) lead.state = ''
    if (!lead.sale) lead.sale = ''
    if (!lead.type) lead.type = 'call'
    if (!lead.content) lead.content = ''
    if (!lead.value) lead.value = 0
    lead.leadSource = 'manual'
    lead.companyId = companyId
    lead.companyName = companyName
    lead.createdBy = createdBy
    lead.createdAt = now
  })
  if (qualityCheck === false){
    alert('imported sheet includes incorrect fields. Please correct your headers and upload again')
    return ''
  } else {
    let output = {leads: result }
    // console.log(output)
    return output
  }
}

// console.log(bulkImportXlsx('../documents/demoSheet.xlsx', 'Convergent1', 'hughlobel'))
