import React, { useState, useContext } from 'react';
import StoreContext from '../contexts/storeContext'
import { useHistory } from "react-router-dom";

import analytics from '../util/analytics'
import { fromCurrency, toCurrency, toUnix, toDatePicker, api } from '../util/utils'
import { authMiddleWare } from '../util/auth';

import { Button, CardContent, Grid, TextField, CardActions } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import axios from 'axios';

const CampaignCreate = (props) => {
	let now = Math.floor(Date.now() / 1000)
	const [campaignName, setCampaignName] = useState('')
	const [campaignAPI, setCampaignAPI] = useState('callrail')
	const [campaignBudget, setCampaignBudget] = useState('$100.00')
	const [campaignStart, setCampaignStart] = useState(now)
	const [campaignEnd, setCampaignEnd] = useState(now)
	const [campaignFilters, setCampaignFilters] = useState('none')
	const [buttonLoading, setButtonLoading] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [visibleTo, setVisibleTo] = useState('company')
	const [stateContext, setStateContext] = useContext(StoreContext)
	const [visibleToggleState, setVisibleToggleState] = useState(props.visible)
	let history = useHistory()
	const isDev = stateContext.userData.role === "developer"

	const postCreateCampaign = (event) => {
		event.preventDefault();
		isDev && console.log('sending new campaign to servers')
		setButtonLoading(true);
		authMiddleWare(history);
		const formRequest = {
			name: campaignName,
			apiSources: campaignAPI,
			budget: fromCurrency(campaignBudget),
			createdBy: stateContext.userData.username,
			companyName: stateContext.company[0],
			companyId: stateContext.company[1],
			visibleTo: visibleTo,
			startDate: toUnix(campaignStart),
			endDate: toUnix(campaignEnd),
			filters: campaignFilters
		};
		axios
			.post(`${api}/campaigns/`, formRequest)
			.then(() => {
				setButtonLoading(false);
				isDev && console.log('campaign added to server')
				props.onToggleView(false)
				setVisibleToggleState(false)
			})
			.then((response) => {
				analytics.logEvent('create_campaign_completed', {username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: formRequest.name})
        axios
					.get(`${api}/campaignList/${stateContext.company[1]}`)
					.then((res) => {
						console.log(res)
						if (res.data.length > 0){
							let campaignList = [...res.data]
							isDev && console.log(campaignList)
							setStateContext({ ...stateContext, campaigns: campaignList, selectedCampaign: -1, campaignDirty: false })
						}
						else (
							setStateContext({ ...stateContext,  campaigns: ['No Campaigns Yet'], selectedCampaign: -1, campaignDirty: false })
						)
					})
					.catch((error) => {
						isDev && console.log('didnt make it')
						history.push('/login')
						console.log(error);
						// setErrorMsg('Error in retrieving the data')   
					})
      })
			.catch((error) => {
				if (error.response.status === 403) {
					history.push('/login');
				}
				isDev && console.log(error);
				setButtonLoading(false)
			});
	};

	const cancelCreate = (event) => {
		event.preventDefault();
		isDev && console.log('cancelling create campaign')
		props.onToggleView(false)
		setVisibleToggleState(false)
	}
	
	const handleCampaignChange = (event) => {
		let name = event.target.name, value = event.target.value;
		switch(name) {
			case 'campName':
				setCampaignName(value);
				break;
			case 'apiSources':
				setCampaignAPI(value);
				break;
			case 'budget':
				setCampaignBudget(value);
				break;
			case 'startDate':
				setCampaignStart(toUnix(value));
				break;
			case 'endDate':
				setCampaignEnd(toUnix(value));
				break;
			case 'filters':
				setCampaignFilters(value);
				break;
			default:
				break;
		}
	}
  
  return (
    <div className={props.visible ? 'campaignCreate visible' : 'campaignCreate hidden'}>
			<form autoComplete="off" noValidate>
				<CardContent>
					<h2>Create Campaign</h2>
					<div className="detailsForm">
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Campaign Name"
									margin="dense"
									name="campName"
									variant="outlined"
									value={campaignName}
									onChange={handleCampaignChange}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Budget"
									margin="dense"
									name="budget"
									variant="outlined"
									value={ campaignBudget }
									onBlur= {() => setCampaignBudget(toCurrency(fromCurrency(campaignBudget))) }
									onChange={handleCampaignChange}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Start Date"
									type="date"
									margin="dense"
									name="startDate"
									variant="outlined"
									value={toDatePicker(campaignStart)}
									onChange={handleCampaignChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="End Date"
									type="date"
									margin="dense"
									name="endDate"
									variant="outlined"
									value={ toDatePicker(campaignEnd) }
									onChange={handleCampaignChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					</div>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={postCreateCampaign}
						disabled={
							buttonLoading ||
							!campaignName ||
							!campaignBudget ||
							!campaignStart ||
							!campaignEnd
						}
					>
						Create Campaign
						{buttonLoading && <CircularProgress size={30} />}
					</Button>
					<Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={cancelCreate}
						// disabled={
						// 	( isUpdated == false )
						// }
					>
						Cancel Create
						{buttonLoading && <CircularProgress size={30} />}
					</Button>
				</CardContent>
				<CardActions />
			</form>
		</div>
  );
}

export default React.memo(CampaignCreate);