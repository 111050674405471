import React, { useState, useContext, useEffect } from 'react';
import StoreContext from '../contexts/storeContext'
import { useHistory } from "react-router-dom";

import { Button, CardContent, Grid, TextField, CardActions, Modal } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

import analytics from '../util/analytics'
import { fromCurrency, toCurrency, toUnix, toDatePicker, api } from '../util/utils'
import { authMiddleWare } from '../util/auth';

import axios from 'axios';

const CampaignDetails = (props) => {
	const [campaignId, setCampaignId] = useState('')
	const [campaignName, setCampaignName] = useState('')
	const [campaignAPI, setCampaignAPI] = useState('')
	const [campaignBudget, setCampaignBudget] = useState('')
	const [errorMsg, setErrorMsg] = useState('')
	const [campaignStart, setCampaignStart] = useState(new Date())
	const [campaignEnd, setCampaignEnd] = useState(new Date())
	const [campaignFilters, setCampaignFilters] = useState('')
	const [buttonLoading, setButtonLoading] = useState(false)
	const [stateContext, setStateContext] = useContext(StoreContext)
	const [openDeleteModal, setOpenDeleteModal] = useState(false);
	const [visibleToggleState, setVisibleToggleState] = useState(props.visible)

	let history = useHistory()
	let delStatus = ''
	const isDev = stateContext.userData.role === "developer"

	useEffect(() => {
		if (stateContext.campaigns[stateContext.selectedCampaign]){
			setCampaignId(stateContext.campaigns[stateContext.selectedCampaign].campaignId)
			setCampaignName(stateContext.campaigns[stateContext.selectedCampaign].name)
			setCampaignAPI(stateContext.campaigns[stateContext.selectedCampaign].apiSources)
			setCampaignBudget(toCurrency(stateContext.campaigns[stateContext.selectedCampaign].budget))
			setCampaignStart(stateContext.campaigns[stateContext.selectedCampaign].startDate)
			setCampaignEnd(stateContext.campaigns[stateContext.selectedCampaign].endDate)
			setCampaignFilters(stateContext.campaigns[stateContext.selectedCampaign].filters)
		}
		isDev && console.log('updating campaign data')
	}, [stateContext.selectedCampaign, stateContext.company, stateContext.campaigns])

		const pushCampaignUpdates = (delStatus) => {
			isDev && console.log('sending campaign updates to server')
			setButtonLoading(true);
			authMiddleWare(history);
			const formRequest = {
				name: campaignName,
				apiSources: campaignAPI,
				budget: fromCurrency(campaignBudget),
				startDate: campaignStart,
				endDate: campaignEnd,
				filters: campaignFilters,
			};
			if (delStatus) {formRequest.deletedStatus = delStatus}
			axios
				.put(`${api}/campaigns/${campaignId}`, formRequest)
				.then(() => {
					setButtonLoading(false);
					isDev && console.log('campaign updated on server')
					let campaignList = [...stateContext.campaigns]
					campaignList[stateContext.selectedCampaign].name = formRequest.name
					campaignList[stateContext.selectedCampaign].budget = formRequest.budget
					campaignList[stateContext.selectedCampaign].startDate = formRequest.startDate
					campaignList[stateContext.selectedCampaign].endDate = formRequest.endDate
					campaignList[stateContext.selectedCampaign].filters = formRequest.filters
					if (delStatus) campaignList[stateContext.selectedCampaign].deletedStatus = delStatus
					if (delStatus && delStatus === 'deleted'){
						campaignList.splice(stateContext.selectedCampaign, 1)
						setStateContext({ ...stateContext, campaigns: [...campaignList], selectedCampaign: -1, campaignDirty: false })
					} else setStateContext({ ...stateContext, campaigns: [...campaignList] })
					if(delStatus) {
						analytics.logEvent('delete_campaign_completed', {username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: formRequest.name})
					} else {
						analytics.logEvent('edit_campaign_completed', {username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: formRequest.name})
					}
					props.onLoadSet(false)
					setVisibleToggleState(false)
				})
				.catch((error) => {
					history.push('/login');
					isDev && console.log(error);
					setButtonLoading(false)
				});
		};
		const handlePushCampaignUpdates = (event, delStatus) => {
			event.preventDefault();
			pushCampaignUpdates(delStatus)
		}
	
	const handleCampaignChange = (event) => {
		let name = event.target.name, value = event.target.value;
		switch(name) {
			case 'name':
				setCampaignName(value);
				break;
			case 'apiSources':
				setCampaignAPI(value);
				break;
			case 'budget':
				setCampaignBudget(value);
				break;
			case 'startDate':
				setCampaignStart(toUnix(value));
				break;
			case 'endDate':
				setCampaignEnd(toUnix(value));
				break;
			case 'filters':
				setCampaignFilters(value);
				break;
			default:
				break;
		}
	}

	const completeCampaignDeletion = () => {
		pushCampaignUpdates('deleted')
		setOpenDeleteModal(false)
	}
	const handleOpenDeleteModal = (event) => {
		event.preventDefault()
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = (event) => {
		event.preventDefault()
    setOpenDeleteModal(false);
	};

	const cancelDetails = (event) => {
		event.preventDefault();
		isDev && console.log('cancelling create campaign')
		props.onLoadSet(false)
		setVisibleToggleState(false)
	}
  
  return (
    <div className={props.visible ? 'campaignDetails visible' : 'campaignDetails hidden'}>
			<form autoComplete="off" noValidate>
				<CardContent>
				<Modal
					open={openDeleteModal}
					onClose={handleCloseDeleteModal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className="confirmationModal">
						<h3>Confirm Deletion</h3>
						<p>Are you sure you want to delete this campaign?</p>
						<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={completeCampaignDeletion}
						>
							Confirm Delete Campaign
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={handleCloseDeleteModal}
						>
							Cancel Delete Campaign
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					</div>
				</Modal>
				<h2>Campaign Details</h2>
					<div className="detailsForm">
						<Grid container spacing={3}>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Campaign Name"
									margin="dense"
									name="name"
									variant="outlined"
									value={campaignName}
									onChange={handleCampaignChange}
									disabled={( stateContext.userData.role === 'user' && stateContext.userData.permissions.editCampaignDetails === false )}
									// onBlur={handleFieldUpdate}
								/>
							</Grid>
							{/* <Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="API Sources"
									margin="dense"
									name="apiSources"
									variant="outlined"
									disabled={true}
									value={campaignAPI }
									onChange={handleCampaignChange}
									// onBlur={handleFieldUpdate}
								/>
							</Grid> */}
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Budget"
									margin="dense"
									name="budget"
									variant="outlined"
									value={ campaignBudget }
									onBlur= {() => setCampaignBudget(toCurrency(fromCurrency(campaignBudget))) }
									onChange={handleCampaignChange}
									disabled={( stateContext.userData.role === 'user' && stateContext.userData.permissions.editCampaignDetails === false )}
									// onBlur={handleFieldUpdate}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="Start Date"
									type="date"
									margin="dense"
									name="startDate"
									variant="outlined"
									value={toDatePicker(campaignStart) }
									onChange={handleCampaignChange}
									disabled={( stateContext.userData.role === 'user' && stateContext.userData.permissions.editCampaignDetails === false )}
									// onBlur={handleFieldUpdate}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item md={6} xs={12}>
								<TextField
									fullWidth
									label="End Date"
									type="date"
									margin="dense"
									name="endDate"
									variant="outlined"
									value={ toDatePicker(campaignEnd) }
									onChange={handleCampaignChange}
									disabled={( stateContext.userData.role === 'user' && stateContext.userData.permissions.editCampaignDetails === false )}
									// onBlur={handleFieldUpdate}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					</div>
					{(stateContext.userData.role === 'admin' || stateContext.userData.role === "owner" || stateContext.userData.role === 'developer' || stateContext.userData.permissions.editCampaignDetails === true) &&
						<Button
							color="primary"
							variant="contained"
							type="submit"
							className="reportButton"
							onClick={handlePushCampaignUpdates}
						>
							Save Campaign Changes
							{buttonLoading && <CircularProgress size={30} />}
						</Button>
					}
					{(stateContext.userData.role === 'admin' || stateContext.userData.role === "owner" || stateContext.userData.role === 'developer' || stateContext.userData.permissions.deleteCampaigns === true) &&
						<Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={handleOpenDeleteModal}
					>
						Delete Campaign
						{buttonLoading && <CircularProgress size={30} />}
					</Button>
					}
				</CardContent>
				<CardActions />
			</form>
		</div>
  );
}

export default React.memo(CampaignDetails);