// company.js

import React, { useState, useEffect, useContext } from 'react';
import StoreContext from '../contexts/storeContext'

import CreateAccount from './createAccount';
import EditAccount from './editAccount';
import { useHistory } from "react-router-dom";

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Card, CardContent, Divider, Button, Modal } from '@material-ui/core';

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

import dayjs from 'dayjs';
import clsx from 'clsx';

import axios from 'axios';

import analytics from '../util/analytics'
import { authMiddleWare } from '../util/auth';
import { api, capitalize } from '../util/utils'

const styles = (theme) => ({
	content: {
		flexGrow: 1,
		padding: theme.spacing(3)
	},
	toolbar: theme.mixins.toolbar,
	root: {},
	details: {
		display: 'flex'
	},
	avatar: {
		height: 110,
		width: 100,
		flexShrink: 0,
		flexGrow: 0
	},
	locationText: {
		paddingLeft: '15px'
	},
	buttonProperty: {
		position: 'absolute',
		top: '50%'
	},
	uiProgess: {
		position: 'fixed',
		zIndex: '1000',
		height: '31px',
		width: '31px',
		left: '50%',
		top: '35%'
	},
	progess: {
		position: 'absolute'
	},
	uploadButton: {
		marginLeft: '8px',
		margin: theme.spacing(1)
	},
	customError: {
		color: 'red',
		fontSize: '0.8rem',
		marginTop: 10
	},
	submitButton: {
		marginTop: '10px'
	}
});

const gridStyle = { minHeight: 550 };

const Company = (props) => {
  
  const [companyName, setCompanyName] = useState('')
  const [buttonLoading, setButtonLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [userList, setUserList] = useState([{}])
  const [userToUpdate, setUserToUpdate] = useState(-1)
  const [createVisible, setCreateVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [stateContext, setStateContext] = useContext(StoreContext)

  let history = useHistory()
  const isDev = stateContext.userData.role === "developer"

  const defaultColumns = [
    // { name: 'id', header: 'ID', defaultFlex: 1, editable: false},
    { name: 'username', header: 'User Name', defaultFlex: 1, editable: false, sortable: false, showColumnMenuLockOptions: false,},
    { name: 'email', header: 'Email', defaultFlex: 1, editable: false, sortable: false, showColumnMenuLockOptions: false, },
    { name: 'firstName', header: 'First Name', defaultFlex: 1, editable: false, sortable: false, showColumnMenuLockOptions: false, },
    { name: 'lastName', header: 'Last Name', defaultFlex: 1, editable: false, sortable: false, showColumnMenuLockOptions: false, },
    { 
      name: 'role', 
      header: 'Role', 
      defaultFlex: 1, 
      editable: false,
      sortable: false,
      showColumnMenuLockOptions: false,
      render: ({ value }) => {
				return capitalize(value)
			} 
     },
    { name: 'date', header: 'Date Added', defaultFlex: 1, editable: false, sortable: false, showColumnMenuLockOptions: false, },
  ];

  let rowLog = [];

  useEffect(() => {
    authMiddleWare(history);
    axios
      .get(`${api}/user/${stateContext.company[1]}`)
      .then((response) => {
        let users = response.data
        if (Array.isArray(users)) {
          users.map((user) => {
            rowLog.push({
              id: user.username, 
              username: user.username,
              date: dayjs(user.createdAt).format('DD/MM/YYYY'), 
              email: user.email,
              country: user.country,
              phoneNumber: user.phoneNumber,
              role: user.role,
              firstName: user.firstName, 
              lastName: user.lastName,
              permissions: user.permissions,
            })
          })
        }
        setUserList(rowLog)
      })
      .catch((error) => {
				if (error.response.status === 403) {
					history.push('/login');
				}
				console.log(error);
				setErrorMsg('Error in retrieving the data');
      });
  }, [stateContext.company])

  const loadUserForm = () => {
    !createVisible && analytics.logEvent(`view_create_account`, {page_title: 'Manage Company', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName})
    setCreateVisible(!createVisible)
  }

  const loadUserEditForm = () => {
    !editVisible && analytics.logEvent(`view_edit_account`, {page_title: 'Manage Company', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName})
    setEditVisible(!editVisible)
  }

  const resetUserForm = (bool) => {
    bool ? setCreateVisible(true) : setCreateVisible(false) 
    bool ? setEditVisible(true) : setEditVisible(false) 
  }

  const handleCreatedUser = () => {
    resetUserForm(false);
    getUserList()
  }

  const getUserList = () => {
    axios
    .get(`${api}/user/${[stateContext.company[1]]}`)
    .then((response) => {
      let users = response.data
      if (Array.isArray(users)) {
        users.map((user) => {
          rowLog.push({
            id: user.username, 
            username: user.username,
            date: dayjs(user.createdAt).format('DD/MM/YYYY'), 
            email: user.email,
            country: user.country,
            phoneNumber: user.phoneNumber,
            role: user.role,
            firstName: user.firstName, 
            lastName: user.lastName,
            permissions: user.permissions,
          })
        })
      }
      setUserList(rowLog)
    })
    .catch((error) => {
      if (error.response.status === 403) {
        history.push('/login');
      }
      console.log(error);
      setErrorMsg('Error in retrieving the data');
    });
  }

  const handleOpenDeleteModal = () => {
    analytics.logEvent(`view_delete_account`, {page_title: 'Manage Company', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName})
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };
  const handleNewSelection = selection => {
    setUserToUpdate(selection)
    console.log(selection)
    if (((stateContext.userData.role === "owner" || stateContext.userData.role === "user") && userList[selection].role !== 'user') || stateContext.userData.username === userList[selection].username){
      setEditVisible(false)
    }
  }
  const completeUserDeletion = () => {
      authMiddleWare(history);
      const authToken = localStorage.getItem('AuthToken');
      axios.defaults.headers.common = { Authorization: `${authToken}` };
      const formRequest = {
        username: userList[userToUpdate].id,
        deletedStatus: 'deleted'
      };
      axios
        .post(`${api}/user`, formRequest)
        .then(() => {
          stateContext.company && analytics.logEvent(`delete_account_completed`, {page_title: 'Manage Company', activeCompany: stateContext.company[0], deletedUsername: formRequest.username, username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName})
          setOpenDeleteModal(false)
          getUserList()
        })
        .catch((error) => {
          if (error.response.status === 403) {
            history.push('/login')
          }
          isDev && console.log(error);
        });
    };

  const { classes, ...rest } = props;

  return (
    <main className={classes.content}>
      <Modal
					open={openDeleteModal}
					onClose={handleCloseDeleteModal}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<div className="confirmationModal">
						<h3>Confirm Deletion</h3>
						<p>Are you sure you want to delete this user?</p>
            <p>{userList[userToUpdate] && userList[userToUpdate].id }</p>
            {(userList[userToUpdate] && userList[userToUpdate].id === stateContext.userData.username ) && 
              <p>You have selected your own account. You may not delete your own account. If you wish to shut down your account, please speak to your account administrator.</p>
            }
            {(userList[userToUpdate] && userList[userToUpdate].role === "owner") && 
              <p>You may not delete the owner of a company account. If you wish to shut down your account, please speak to your account administrator.</p>
            }
            {userList[userToUpdate] && 
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className={"loadReport reportButton"}
                onClick={completeUserDeletion}
                disabled={
                  ( userList[userToUpdate].id === stateContext.userData.username 
                    || ( stateContext.userData.role === "user" && userList[userToUpdate].role === "owner" )
                    || ( stateContext.userData.role === "owner" && userList[userToUpdate].role === "owner" ) )
                }
              >
                Confirm Delete User
                {buttonLoading && <CircularProgress size={30} />}
              </Button>
            }
					<Button
							color="primary"
							variant="contained"
							type="submit"
							className={"loadReport reportButton"}
							onClick={handleCloseDeleteModal}
						>
							Cancel Delete Lead
							{buttonLoading && <CircularProgress size={30} />}
					</Button>
					</div>
				</Modal>
      <div className={classes.toolbar} />
      <br />
      <Card {...rest} className={clsx(classes.root, classes)}>
        <CardContent container>
          <div className={classes.details}>
            <div className='fullWidth'>
              <h2>{stateContext.company[0]} Company Accounts</h2>
              <div className="leadsChart">
                <ReactDataGrid
                  idProperty="id"
                  columns={defaultColumns}
                  dataSource={userList}
                  style={gridStyle}
                  onActiveIndexChange={handleNewSelection}
                  pagination="local"
                  defaultLimit="25"
                  alwaysShowTrack="true"
                  autoHide="false"
                  nativeScroll="true"
                  native
                />
              </div>
              {(stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer' || stateContext.userData.role === "owner" || stateContext.userData.permissions.createUserAccounts === true) &&
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className='reportButton'
                  onClick={loadUserForm}
                >
                  { !createVisible ? "Create New User" : "Cancel New User" }
                  {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
                </Button>
              }
              {(stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer' || stateContext.userData.role === "owner" || stateContext.userData.permissions.editUserAccounts === true) &&
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className='reportButton'
                  onClick={loadUserEditForm}
                  disabled={
                    ( userToUpdate === -1 || stateContext.userData.username === userList[userToUpdate].username || ( (stateContext.userData.role === 'owner' || stateContext.userData.role === 'user') && userList[userToUpdate].role !== 'user') )
                  }
                >
                  { !editVisible ? "Edit User" : "Cancel Edit User" }
                  {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
                </Button>
              }
              {(stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer' || stateContext.userData.role === "owner" || stateContext.userData.permissions.deleteUserAccounts === true) &&
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className='reportButton'
                  onClick={handleOpenDeleteModal}
                  disabled={
                    ( userToUpdate === -1)
                  }
                >
                  Delete User
                  {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
                </Button>
              }
              {isDev && 
              <div>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className='reportButton'
                  onClick={() => console.log(userList)}
                >
                    Log Users to Console
                    {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  className='reportButton'
                  onClick={() => console.log(userList[userToUpdate])}
                >
                    Log Selected User to Console
                    {buttonLoading && <CircularProgress size={30} className={classes.progess} />}
                </Button>
              </div>
              }
            </div>
          </div>
          <div className={classes.progress} />
        </CardContent>
        <Divider />
      </Card>

      <br />
      { createVisible && <CreateAccount submitReset={handleCreatedUser} companyName={companyName} role={stateContext.userData.role} /> }
      { editVisible && <EditAccount submitReset={handleCreatedUser} user={userList[userToUpdate]} /> }
      <br />
      <Card>
        {(stateContext.userData.role == 'admin' || stateContext.userData.role == 'developer' || stateContext.userData.role == 'superadmin') &&
          <CardContent>
            <h4>API Key</h4><p><strong>(for use with integration setup):</strong>&nbsp;{stateContext.company[1]}</p>
          </CardContent>
      }
      </Card>
    </main>
  );
}

export default withStyles(styles)(Company);
