import React, { useState, useEffect, useContext } from 'react';
import StoreContext from '../contexts/storeContext'

import analytics from '../util/analytics'
import { toUnix, toDatePicker } from '../util/utils'

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Button, CardContent, Grid, TextField, CardActions, InputLabel, Select, Input, Chip, MenuItem, FormGroup, FormLabel, FormControl, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

let now = Math.floor(Date.now() / 1000)

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ReportFilters = (props) => {
	const [buttonLoading, setButtonLoading] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')
	const [stateContext, setStateContext] = useContext(StoreContext)
	const [visibleToggleState, setVisibleToggleState] = useState(props.visible)
	const [uniqueSources, setUniqueSources] = useState([])
	const [uniqueOrigins, setUniqueOrigins] = useState([])
	const [uniqueCities, setUniqueCities] = useState([])
	const [uniqueStates, setUniqueStates] = useState([])
	const [uniqueCountries, setUniqueCountries] = useState([])
	const [uniqueKeywords, setUniqueKeywords] = useState([])
	const [uniqueLandingPages, setUniqueLandingPages] = useState([])
	const [uniqueTypes, setUniqueTypes] = useState([])
	const [filterValues, setFilterValues] = useState({
		qualifiedYes: false,
		qualifiedNo: false,
		qualifiedPending: false,
		completedYes: false,
		completedNo: false,
		completedNA: false,
		saleYes: false,
		saleNo: false,
		salePending: false,
		filterDupes: '0',
		filterNumberDupes: '0',
		filterEmailDupes: '0',
		startDate: "",
		endDate: "",
		sourceFilters: [],
		origins: [],
		cities: [],
		states: [],
		countries:  [],
		keywords: [],
		types: [],
		landingPages: []
	})

	const isDev = stateContext.userData.role === "developer"

	useEffect(() => {
		let sources = [], origins = [], cities = [], states = [], countries = [], types = [], keywords = [], landingPages = [], keywordsGathered = []
		stateContext.leads.map(function(lead) {
			sources.push(lead.source)
			origins.push(lead.leadSource)
			cities.push(lead.city)
			states.push(lead.state)
			countries.push(lead.country)
			if(lead.keywords){
				if(typeof lead.keywords === 'string'){
					keywords.push(lead.keywords.replace(" +", ", ").replace("+", ""))
				} else {keywords = lead.keywords}
			}
			landingPages.push(lead.landingPage)
			types.push(lead.type)
		})
		keywords.forEach(set => {
			let list = set.split(', ')
			list.forEach(item => {keywordsGathered.push(item)})
		})
		let uniqueSourceList = [...new Set(sources)]
		let uniqueOriginList = [...new Set(origins)]
		let uniqueCityList = [...new Set(cities)]
		let uniqueStateList = [...new Set(states)]
		let uniqueCountryList = [...new Set(countries)]
		let uniqueTypeList = [...new Set(types)]
		let uniqueKeywordsList = [...new Set(keywordsGathered)]
		let uniqueLandingPageList = [...new Set(landingPages)]
		setUniqueSources(uniqueSourceList)
		setUniqueOrigins(uniqueOriginList)
		setUniqueCities(uniqueCityList)
		setUniqueStates(uniqueStateList)
		setUniqueCountries(uniqueCountryList)
		setUniqueTypes(uniqueTypeList)
		setUniqueKeywords(uniqueKeywordsList)
		setUniqueLandingPages(uniqueLandingPageList)
	},[stateContext.leads])

	const classes = useStyles();

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	}

	const resetFilters = () => {
		let reset = {
			filterDupes: '0',
			filterNumberDupes: '0',
			filterEmailDupes: '0',
			qualifiedYes: false,
			qualifiedNo: false,
			qualifiedPending: false,
			completedYes: false,
			completedNo: false,
			completedNA: false,
			saleYes: false,
			saleNo: false,
			salePending: false,
			startDate: "",
			endDate: "",
			sourceFilters: [],
			origins: [],
			cities: [],
			states: [],
			countries:  [],
			types: [],
			keywords: [],
			landingPages: []
		}
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`clear_all_lead_filters`, {page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
		setFilterValues(reset)
		let campaignUpdates = [...stateContext.campaigns]
		if(campaignUpdates[stateContext.selectedCampaign]) campaignUpdates[stateContext.selectedCampaign].filters = reset
		setStateContext({ ...stateContext, campaigns: campaignUpdates, dirty: true })
	}
	const handleClear = (field) => {
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`clear_lead_filter`, {updateParameterName: field, page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})
		let newValues = { ...filterValues}
		if (field === 'qualified' || field === 'completed' || field === 'sale'){
			let yes = `${field}Yes`, no = `${field}No`, pending = `${field}Pending`, na = `${field}NA`
			newValues[yes] = false;
			newValues[no] = false;
			if (field === 'completed') {newValues[na] = false}
			else {newValues[pending] = false} 
		}
		else if (field === 'startDate' || field === 'endDate') {newValues[field] = ''}
		else if (field === 'filterDupes') {newValues.filterDupes = '0'}
		else if (field === 'filterNumberDupes') {newValues.filterNumberDupes = '0'}
		else if (field === 'filterEmailDupes') {newValues.filterEmailDupes = '0'}
		else { newValues[field] = [] }
		setFilterValues(newValues)
		let campaignUpdates = [...stateContext.campaigns]
		campaignUpdates[stateContext.selectedCampaign] && (campaignUpdates[stateContext.selectedCampaign].filters = newValues)
		setStateContext({ ...stateContext, campaigns: campaignUpdates, dirty: true })
	}
	const sendFilter = () => {
		isDev && console.log(filterValues)
		let campaignUpdates = [...stateContext.campaigns]
		campaignUpdates[stateContext.selectedCampaign] && (campaignUpdates[stateContext.selectedCampaign].filters = filterValues)
		setStateContext({ ...stateContext, campaigns: campaignUpdates })
	} 

	useEffect(() => {
		if (stateContext.dirty == false) resetFilters()
	}, [stateContext.dirty])

  const handleChange = (event) => {
		let formName = event.target.name, checked = event.target.checked, value = event.target.value
		stateContext.campaigns[stateContext.selectedCampaign] && analytics.logEvent(`update_lead_filters`, {updateParameterName: formName, updateParameterValue: value, updateParameterChecked: checked, page_title: 'Campaign Report', username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: stateContext.campaigns[stateContext.selectedCampaign].name})

		let newValues = {}
		let campaignUpdates = [...stateContext.campaigns]
		if (formName === "startDate" || formName === "endDate") value = toUnix(value)
		isDev && console.log(`field: ${formName}, checked: ${checked}, value: ${value}`)
		if (checked !== undefined ){
			newValues = { ...filterValues, [event.target.name]: event.target.checked }
			setFilterValues({ ...filterValues, [event.target.name]: event.target.checked });
		} 
		if (value !== undefined && value !== "" ) {
			newValues = { ...filterValues, [event.target.name]: event.target.value }
			setFilterValues({ ...filterValues, [event.target.name]: event.target.value });
		}
		campaignUpdates[stateContext.selectedCampaign].filters = newValues;
		setStateContext({ ...stateContext, campaigns: campaignUpdates })
	};
	
  return (
    <div className={props.visible ? 'campaignCreate visible' : 'campaignCreate visible'}>
			<form autoComplete="off" noValidate>
				<CardContent container>
					<h2>Filter Leads</h2>
					<div className="detailsForm leadFilters">
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Sources</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="sourceFilters"
										value={filterValues.sourceFilters}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueSources.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('sourceFilters')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Origins</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="origins"
										value={filterValues.origins}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueOrigins.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('origins')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Cities</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="cities"
										value={filterValues.cities}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueCities.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('cities')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">States</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="states"
										value={filterValues.states}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueStates.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('states')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Countries</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="countries"
										value={filterValues.countries}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueCountries.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('countries')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Types</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="types"
										value={filterValues.types}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueTypes.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('types')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Keywords</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="keywords"
										value={filterValues.keywords}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueKeywords.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('keywords')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Landing Page</InputLabel>
									<Select
										labelId="demo-mutiple-chip-label"
										id="demo-mutiple-chip"
										multiple
										name="landingPages"
										value={filterValues.landingPages}
										onChange={handleChange}
										input={<Input id="select-multiple-chip" />}
										renderValue={(selected) => (
											<div className={classes.chips}>
												{selected.map((value) => (
													<Chip key={value} label={value} className={classes.chip} />
												))}
											</div>
										)}
										MenuProps={MenuProps}
									>
										{uniqueLandingPages.map((source) => (
											<MenuItem key={source} value={source} >
												{source}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('landingPages')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Duplicate Names</InputLabel>
									<Select
										labelId="campaignSelectLabel"
										id="campaignSelect"
										name="filterDupes"
										value={filterValues.filterDupes}
										onChange={handleChange}
									>
										<MenuItem key='filterDupes' value='0' >
											Don't Filter
										</MenuItem>
										<MenuItem key='filterDupes' value='1' >
											Show Most Recent
										</MenuItem>
										<MenuItem key='filterDupes' value='2' >
											Show Oldest
										</MenuItem>
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('filterDupes')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Duplicate Numbers</InputLabel>
									<Select
										labelId="campaignSelectLabel"
										id="campaignSelect"
										name="filterNumberDupes"
										value={filterValues.filterNumberDupes}
										onChange={handleChange}
									>
										<MenuItem key='filterNumberDupes' value='0' >
											Don't Filter
										</MenuItem>
										<MenuItem key='filterNumberDupes' value='1' >
											Show Most Recent
										</MenuItem>
										<MenuItem key='filterNumberDupes' value='2' >
											Show Oldest
										</MenuItem>
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('filterNumberDupes')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<FormControl className={classes.formControl}>
									<InputLabel id="demo-mutiple-chip-label">Duplicate Email Addresses</InputLabel>
									<Select
										labelId="campaignSelectLabel"
										id="campaignSelect"
										name="filterEmailDupes"
										value={filterValues.filterEmailDupes}
										onChange={handleChange}
									>
										<MenuItem key='filterEmailDupes' value='0' >
											Don't Filter
										</MenuItem>
										<MenuItem key='filterEmailDupes' value='1' >
											Show Most Recent
										</MenuItem>
										<MenuItem key='filterEmailDupes' value='2' >
											Show Oldest
										</MenuItem>
									</Select>
								</FormControl>
								<p className="filterClear" onClick={() => handleClear('filterEmailDupes')}>⮿</p>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="Start Date"
									type="date"
									margin="dense"
									name="startDate"
									variant="outlined"
									// value={toDatePicker(campaignStart)}
									onChange={handleChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									fullWidth
									label="End Date"
									type="date"
									margin="dense"
									name="endDate"
									variant="outlined"
									// value={ toDatePicker(campaignEnd) }
									onChange={handleChange}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<div className='filterTitle'>
										<FormLabel>Qualified (Select one or more)</FormLabel>
										<p className="filterClear checks" onClick={() => handleClear('qualified')}>⮿</p>
									</div>
									<FormGroup>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.qualifiedYes} onChange={handleChange} name="qualifiedYes" />}
											label="Yes"
										/>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.qualifiedNo} onChange={handleChange} name="qualifiedNo" />}
											label="No"
										/>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.qualifiedPending} onChange={handleChange} name="qualifiedPending" />}
											label="Pending"
										/>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<div className='filterTitle'>
										<FormLabel>Sale (Select one or more)</FormLabel>
										<p className="filterClear checks" onClick={() => handleClear('sale')}>⮿</p>
									</div>
									<FormGroup>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.saleYes} onChange={handleChange} name="saleYes" />}
											label="Yes"
										/>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.saleNo} onChange={handleChange} name="saleNo" />}
											label="No"
										/>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.salePending} onChange={handleChange} name="salePending" />}
											label="Pending"
										/>
									</FormGroup>
								</FormControl>
							</Grid>
							<Grid item xs={12}>
								<FormControl>
									<div className='filterTitle'>
										<FormLabel>Completed (Select one or more)</FormLabel>
										<p className="filterClear checks" onClick={() => handleClear('completed')}>⮿</p>
									</div>
									<FormGroup>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.completedYes} onChange={handleChange} name="completedYes" />}
											label="Yes"
										/>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.completedNo} onChange={handleChange} name="completedNo" />}
											label="No"
										/>
										<FormControlLabel 
											control={<Checkbox checked={filterValues.completedNA} onChange={handleChange} name="completedNA" />}
											label="N/A"
										/>
									</FormGroup>
								</FormControl>
							</Grid>
						</Grid>
					</div>
					{/* <Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={(event) => {event.preventDefault(); sendFilter()}}
					>
						Filter Report
						{buttonLoading && <CircularProgress size={30} />}
					</Button> */}
					<Button
						color="primary"
						variant="contained"
						type="submit"
						className="reportButton"
						onClick={(event) => {event.preventDefault();resetFilters()}}
					>
						Clear Filters
						{buttonLoading && <CircularProgress size={30} />}
					</Button>
					{
						isDev &&
						<Button color="primary" className="reportButton" type="submit" variant="contained" onClick={(event) => {event.preventDefault();isDev && console.log(filterValues)}}>Log Filters</Button>
					}
				</CardContent>
				<CardActions />
			</form>
		</div>
  );
}

export default React.memo(ReportFilters);