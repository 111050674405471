import React, { useState, useContext, useEffect } from 'react';

import StoreContext from '../contexts/storeContext'
import { useHistory } from "react-router-dom";

import { CardContent, FormControl, InputLabel, Select, MenuItem, Button, CircularProgress } from '@material-ui/core';

import analytics from '../util/analytics'
import { toCurrency, capitalize, api } from '../util/utils'
import { authMiddleWare } from '../util/auth';

import axios from 'axios'

const CampaignSelect = ({ onCampaignSelect, onToggleView, onCreateView, onLoadSet, detailsVisible }) => {

	const [stateContext, setStateContext] = useContext(StoreContext)
	let initRows = [...stateContext.leads];
	const [buttonLoading] = useState(false)
	const [dataLoading, setDataLoading] = useState(false)
	const [displayName, setDisplayName] = useState(stateContext.campaigns[stateContext.selectedCampaign] ? stateContext.campaigns[stateContext.selectedCampaign].name : -1)
	const [toggleState, setToggleState] = useState(false)
	let history = useHistory()

	const isDev = stateContext.userData.role === "developer"
	
	const loadReport = () => {
		onLoadSet(true)
		setDataLoading(true)
		authMiddleWare(history);
		isDev && console.log('fetching data')
		let startDate = stateContext.campaigns[stateContext.selectedCampaign].startDate
		let endDate = Number(stateContext.campaigns[stateContext.selectedCampaign].endDate) + 86400
		axios.get(`${api}/leads/${startDate}/${endDate}/${stateContext.company[1]}`)
			.then((response) => {
				initRows = []
				response.data.map((lead) => {
					let valueAsCurrency = !Number.isNaN(parseFloat(lead.value)) ? toCurrency(lead.value) : ""
					if (lead.qualified === "Qualified Lead" || lead.qualified === "good_lead" || lead.qualified === "previously_marked_good_lead") lead.qualified = "yes"
					if (lead.qualified === "Not a Lead" || lead.qualified === "not_a_lead") lead.qualified = "no"
					if (lead.qualified === "" || lead.qualified === "Not Scored" || lead.qualified === undefined) lead.qualified = "pending"
					if (lead.recordingUrl) lead.content = lead.recordingUrl
					if (lead.form_data) lead.content = lead.form_data
					if (Array.isArray(lead.keywords)){lead.keywords = lead.keywords.join(", ")}
					initRows.push({
						id: lead.leadId, 
						leadSource: capitalize(lead.leadSource),
						date: lead.startTime, 
						type: lead.type,
						landingPage: lead.landingPage,
						source: lead.source, 
						name: lead.name, 
						email: lead.email,
						phone: lead.phoneNumber,
						note: lead.note,
						city: lead.city,
						state: lead.state,
						country: lead.country,
						keywords: lead.keywords,
						content: lead.content,
						qualified: lead.qualified,
						completed: lead.completed,
						sale: lead.sale,
						value: valueAsCurrency,
					})
					return response;
				})
				setStateContext({ ...stateContext, leads: [...initRows], filteredLeads: [...initRows], dirty: false })
				onLoadSet(false)
				setDataLoading(false)
				analytics.logEvent('load_report', {username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: displayName})
				isDev && console.log('data fetched')
			})
			.catch((error) => {
				console.error(error)
				setDataLoading(false)
				// if (error.response.status === 403) {
				// 	history.push('/login');
				// }
			})
	};

	const handleCampaignSelect = (event) => {
		stateContext.campaigns.forEach((el, i) => {
			isDev && console.log('updating selected campaign id')
			onCampaignSelect(event.target.value)
			if(event.target.value !== -1){
				if (el.name === event.target.value) {
					setStateContext({ ...stateContext, selectedCampaign: i })
					setDisplayName(event.target.value)
					return;
				}
			} else {setDisplayName(-1); setToggleState(false); onToggleView(false);}
		})
	};
	
	useEffect(() => {
		setDisplayName(-1);
		setStateContext({ ...stateContext, campaignDirty: true })
		console.log('reset')
	}, [stateContext.campaignDirty == false])

	const handleViewToggle = () => {
		if(!toggleState){
			analytics.logEvent('view_campaign_details', {username: stateContext.userData.username, role: stateContext.userData.role, companyName: stateContext.userData.companyName, campaignCompany: stateContext.company[0], campaignName: displayName})
		}
		onToggleView(!detailsVisible)
		setToggleState(!toggleState)
	}

	const handleCreateToggle = () => {
		onCreateView(true)
	}

  return (
		<div className='campaignSelect'>
			<CardContent>
				<h1>Campaign Report</h1>
				<div className='selectContent'>
				<div className='selectButtons'>
				<FormControl variant="outlined" id="campaignSelectForm">
					<InputLabel>1. Select Campaign</InputLabel>
					<Select
						labelId="campaignSelectLabel"
						id="campaignSelect"
						value={displayName}
						onChange={handleCampaignSelect}
					>
					{stateContext.campaigns.length === 0 && <MenuItem value={-1}>No Campaigns Yet!</MenuItem>}
					{stateContext.campaigns.length > 0 && <MenuItem value={-1}>1. Select Campaign</MenuItem>}
					{stateContext.campaigns.map((data) => (
						!data.deletedStatus && <MenuItem value={data.name}>{data.name}</MenuItem>
					))}
				</Select>
			</FormControl>
			<Button
				color="primary"
				variant="contained"
				type="submit"
				className="loadReport reportButton bigButton"
				onClick={loadReport}
				disabled={
					( displayName === -1 || dataLoading === true )
				}
			>
				2. Load Campaign
				{buttonLoading && <CircularProgress size={30} />}
			</Button>
			</div>
			<div>
			<Button
				color="primary"
				variant="contained"
				type="submit"
				className="loadReport reportButton"
				onClick={handleViewToggle}
				disabled={
					( displayName === -1 )
				}
			>
				{detailsVisible ? 'Hide Campaign Details' : 'View Campaign Details'}
				{buttonLoading && <CircularProgress size={30} />}
			</Button>
			{(stateContext.userData.role === 'admin' || stateContext.userData.role === 'developer' || stateContext.userData.role === "owner" || stateContext.userData.permissions.createCampaigns === true) &&
				<Button
					color="primary"
					variant="contained"
					type="submit"
					className="loadReport reportButton"
					onClick={handleCreateToggle}
				>
					Create New Campaign
					{buttonLoading && <CircularProgress size={30} />}
				</Button>
			}
			
			{
				isDev &&
				<Button color="primary" variant="contained" className="reportButton" onClick={() => { console.log(stateContext) }} >Log Context</Button>
			}
			</div>
			</div>
		</CardContent>
	</div>
  );
}

export default React.memo(CampaignSelect);